import React from "react";
import { ProvisionsUIProvider } from "../ProvisionsUIContext";
import { ProvisionsHome } from "./ProvisionsHome";

export function ProvisionsPage({ history }) {
  const provisionsUIEvents = {
    openInfoProvisionPage: (row) => {
      history.push(`/prestazioni/${row.Id}/dettaglio`);
    },
  }

  return (
    <ProvisionsUIProvider provisionsUIEvents={provisionsUIEvents}>
      <ProvisionsHome />
    </ProvisionsUIProvider>
  );
}
