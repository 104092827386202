import { usersSlice } from "./usersSlice";
import { spinnerCreate } from "../spinner/spinnerActions";
import { companies } from "../../utils/constant";

const { actions } = usersSlice;

export const fetchCustomers = (user) => async dispatch => {
    dispatch(spinnerCreate(true));
    try {
        const response = [];
        companies.forEach(
            (el) => {
                user.associatedCustomersId.forEach((userCustomer) => {
                    if (userCustomer == el.CustomerId) {
                        response.push(el);
                    }
                });
            }
        );
        dispatch(actions.usersCustomersFetched({ entities: response }));
        dispatch(spinnerCreate(false));
    }
    catch (error) {
        dispatch(spinnerCreate(false));
        console.error(error);
    }
}

export const setCustomerId = customer => async dispatch => {
    dispatch(actions.setSelectedCustomer({ customer }));
    dispatch(actions.clearAllFilters());
}