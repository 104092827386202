import React, {useEffect, useMemo, useState} from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {StatusPatientsColumnFormatter} from "../../../component/table/column-formatters/StatusColumnFormatter";
import {GenderColumnFormatter} from "../../../component/table/column-formatters/TypeColumnFormatter";
import {defaultPatientSorted} from "../PatientsUIHelpers";
import {
    sortCaret,
    headerSortingClasses,
} from "../../../../_metronic/_helpers";
import {usePatientsUIContext} from "../PatientsUIContext";
import * as actions from "../../../actions/patients/patientsActions";
import {tableSortByDate} from "../../../component/table/tableSort";
import {InfiniteScrollTable} from "../../../component/table/InfiniteScrollTable";
import {ExportButton} from "../../../component/exportbutton/ExportButton";
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar
} from "../../../../_metronic/_partials/controls";

export function PatientsTable() {
    const dispatch = useDispatch();
    // Patients UI Context
    const patientsUIContext = usePatientsUIContext();
    const patientsUIProps = useMemo(() => {
        return {
            queryParams: patientsUIContext.queryParams,
            openInfoPatientPage: patientsUIContext.openInfoPatientPage,
        };
    }, [patientsUIContext]);

    const {currentState} = useSelector(
        (state) => ({currentState: state.patients}),
        shallowEqual
    );

    const {patientsList, pagination, totalCount} = currentState;

    const [selectedRows, setSelectedRows] = useState([]);
    const [sortTable, setSortTable] = useState(undefined);

    useEffect(() => {
        if (sortTable) {
            dispatch(actions.fetchPatients(patientsUIProps.queryParams, null, sortTable));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patientsUIProps.queryParams, dispatch]);

    const columns = [
        {
            dataField: "Id",
            text: "Codice Paziente",
            headerAlign: 'center',
            align: "center",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses
        },
        {
            dataField: "Nominative",
            text: "Nominativo",
            sort: true,
            align: "center",
            headerAlign: 'center',
            sortCaret: sortCaret,
            headerSortingClasses
        },
        {
            dataField: "Gender",
            text: "Sesso",
            sort: true,
            align: "center",
            headerAlign: 'center',
            sortCaret: sortCaret,
            formatter: GenderColumnFormatter,
            headerSortingClasses
        },
        {
            dataField: "FiscalCode",
            text: "Codice Fiscale",
            headerAlign: 'center',
            align: "center",
            sort: false
        },
        {
            dataField: "District",
            text: "Distretto Sanitario",
            headerAlign: 'center',
            align: "center",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses
        },
        {
            dataField: "Status",
            text: "Stato",
            headerAlign: 'center',
            align: "center",
            sort: true,
            sortCaret: sortCaret,
            formatter: StatusPatientsColumnFormatter,
            headerSortingClasses
        },
        {
            dataField: "Prescriptions",
            text: "Trattamenti",
            sort: true,
            align: "center",
            headerAlign: 'center',
            sortCaret: sortCaret,
            headerSortingClasses
        },
        {
            dataField: "Provision",
            text: "Prestazioni Erogate",
            sort: true,
            align: "center",
            headerAlign: 'center',
            sortCaret: sortCaret,
            headerSortingClasses
        },
        {
            dataField: "InchargeDate",
            text: "Data Presa",
            sort: true,
            align: "center",
            headerAlign: 'center',
            sortCaret: sortCaret,
            headerSortingClasses,
            sortFunc: tableSortByDate
        },
        {
            dataField: "DischargeDate",
            text: "Data Dismissione",
            sort: true,
            align: "center",
            headerAlign: 'center',
            sortCaret: sortCaret,
            headerSortingClasses,
            sortFunc: tableSortByDate
        },
        {
            dataField: "TherapyDays",
            text: "Giorni Terapia",
            headerAlign: 'center',
            align: "center",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses
        },
    ];


    const patientListTitle = <div className={''}><span>Lista pazienti</span> {totalCount !== undefined &&
        <small className={'ml-5'}>{totalCount} {totalCount === 1 ? 'Risultato' : 'Risultati'}</small>}</div>
    return (
        <Card className="customHeaderPadding">
            <CardHeader title={patientListTitle} className="font-weight-bolder">
                <CardHeaderToolbar>
                    <ExportButton
                        data={selectedRows.length > 0 ? selectedRows : []}
                        loadData={(callback) => dispatch(actions.fetchPatientsForFile(patientsUIProps.queryParams, sortTable, callback))}
                        columns={columns}
                        fileName={"pazienti"}
                    />
                </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
                <InfiniteScrollTable
                    keyField={"PrescriptionId"}
                    id={"patientTable"}
                    columns={columns}
                    data={patientsList}
                    defaultSorted={defaultPatientSorted}
                    pagination={pagination}
                    onSelectRow={patientsUIProps.openInfoPatientPage}
                    onSelectCheckBox={(row, isSelect) => {
                        if (isSelect) {
                            setSelectedRows([...selectedRows, row]);
                        } else {
                            setSelectedRows(selectedRows.filter((el) => {
                                return el.Id !== row.Id
                            }));
                        }
                    }}
                    hoverEnable={true}
                    loadData={(event, tableInfo) => {
                        if (tableInfo) {
                            setSortTable({dataField: tableInfo.sortField, order: tableInfo.sortOrder});
                            dispatch(actions.fetchPatients(patientsUIProps.queryParams, pagination, {
                                dataField: tableInfo.sortField,
                                order: tableInfo.sortOrder
                            }, true));
                        } else {
                            dispatch(actions.fetchPatients(patientsUIProps.queryParams, pagination, sortTable, false));
                        }
                    }}
                    noData={"Non sono presenti dati"}
                />
            </CardBody>
        </Card>
    );
}
