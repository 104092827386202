import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
//import { initialDeviceFilter } from "./DevicesUIHelpers";

const DevicesUIContext = createContext();

export function useDevicesUIContext() {
    return useContext(DevicesUIContext);
}

export function DevicesUIProvider({ devicesUIEvents, children }) {

    //se presente prendo lo stato iniziale dei filtri dal local storage
    let devicesSelectedFilter = JSON.parse(localStorage.getItem('devicesSelectedFilters-...'));

    if (!devicesSelectedFilter) {
        devicesSelectedFilter = {
            filter: {
                SearchText: "",
                DeliveryDate: "",
                PickUpDate: "",
                DeviceType: [],
                DeviceDistrict: []
                //DeviceLot: []
            },
        };
    }

    //setto lo stato iniziale dei filtri
    const [queryParams, setQueryParamsBase] = useState(devicesSelectedFilter);

    //funzione che viene chiamata dai cari componenti ogni volta che cambia un filtro
    const setQueryParams = useCallback(nextQueryParams => {
        setQueryParamsBase(prevQueryParams => {
            if (isFunction(nextQueryParams)) {
                nextQueryParams = nextQueryParams(prevQueryParams);
            }

            if (isEqual(prevQueryParams, nextQueryParams)) {
                return prevQueryParams;
            }
            return nextQueryParams;
        });
    }, []);

    const value = {
        queryParams,
        setQueryParamsBase,
        setQueryParams,
        openInfoDevicePage: devicesUIEvents.openInfoDevicePage
    };

    return <DevicesUIContext.Provider value={value}>{children}</DevicesUIContext.Provider>;
}