import React from "react";
import { PatientsUIProvider } from "../PatientsUIContext";
import { PatientsHome } from "./PatientsHome";

export function PatientsPage({ history }) {
  const patientsUIEvents = {
    openInfoPatientPage: (row) => {
      history.push(`/pazienti/${row.Id}/dettaglio`);
    },
  }

  return (
    <PatientsUIProvider patientsUIEvents={patientsUIEvents}>
      <PatientsHome />
    </PatientsUIProvider>
  );
}
