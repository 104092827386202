import * as requestFromServer from "./provisionsCrud";
import { spinnerCreate } from "../spinner/spinnerActions";
import { provisionsSlice } from "./provisionsSlice";
import moment from "moment";

const { actions } = provisionsSlice;

function getCustomerId() {
    return localStorage.getItem("customerId");
}

function getProvisionsQueryParams(queryParams, isForChart, pagination, sort, isChangedSort) {
    let provisionsDate = queryParams.filter.ProvisionsDate;
    let status = queryParams.filter.Status;
    let districts = queryParams.filter.District;
    let searchText = queryParams.filter.SearchText;
    let type = queryParams.filter.Type;
    let customerId = getCustomerId();
    let params = 'customerId:"' + customerId + '" ';

    if (!isForChart) {
        if (isChangedSort) {
            params += 'first:50';
        } else {
            if (pagination) {
                params += 'first:' + pagination.Row + '';
                if (pagination.EndCursor) {
                    params += ' after:"' + pagination.EndCursor + '"';
                }
            } else {
                params += 'first:50';
            }
        }
    }

    if (provisionsDate || status.length > 0 || districts.length > 0 || searchText || type.length > 0) {
        params += ' where:{';

        if (provisionsDate) {
            params += 'provisionDate_gte:"' + moment(provisionsDate.split('-')[0].trim(), "DD/MM/YYYY").format("YYYY-MM-DD") + 'T00:00:00Z" provisionDate_lte:"' + moment(provisionsDate.split('-')[1].trim(), "DD/MM/YYYY").format("YYYY-MM-DD") + 'T23:59:59Z"';
        }

        if (type.length > 0) {
            type.forEach((el, index) => {
                if (index === 0) {
                    params += ' provisionType_in:[' + el + '';
                } else {
                    params += ',' + el + '';
                }
            });
            params += ']';
        }

        if (status.length > 0) {
            status.forEach((el, index) => {
                if (index === 0) {
                    params += ' provisionState_in:[' + el + '';
                } else {
                    params += ',' + el + '';
                }
            });
            params += ']';
        }

        if (districts.length > 0) {
            districts.forEach((el, index) => {
                if (index === 0) {
                    if (el === "-") {
                        params += ' district_in:[' + null + '';
                    } else {
                        params += ' district_in:["' + el + '"';
                    }
                } else {
                    if (el === "-") {
                        params += ',' + null + '';
                    } else {
                        params += ',"' + el + '"';
                    }
                }
            });
            params += ']';
        }

        if (searchText) {
            params += ' OR:[{patientFullNameSearch_contains:"' + searchText.toUpperCase() + '"},{staffInitials_contains:"' + searchText.toUpperCase() + '"}]';
        }

        params += '}';
    }

    if (!isForChart) {
        let field = [];

        if (sort.dataField === "ProvisionType") {
            field.push("provisionType");
        } else if (sort.dataField === "PatientName") {
            field.push("patientLastName");
            field.push("patientFirstName");
        } else if (sort.dataField === "District") {
            field.push("district");
        } else if (sort.dataField === "ProvisionDate") {
            field.push("provisionDate");
        } else if (sort.dataField === "Status") {
            field.push("provisionState");
        } else if (sort.dataField === "DocumentNumber") {
            field.push("documentNumber");
        } else if (sort.dataField === "PatientId") {
            field.push("patientId");
        }

        params += ' order_by: { ';

        field.forEach((el, index) => {
            if (index === 0) {
                params += el + ':' + sort.order.toUpperCase();
            } else {
                params += ',' + el + ':' + sort.order.toUpperCase();
            }
        });

        params += ' provisionId:DESC}';
    }

    return params;
}

export const fetchProvisionsInProgress = queryParams => async dispatch => {
    dispatch(spinnerCreate(true));
    let provisionsDate = queryParams.filter.ProvisionsDate;
    let customerId = getCustomerId();
    let params = 'customerId:"' + customerId + '" ';
    const query =  getProvisionsQueryParams(queryParams, true);
    params += 'provisionTableQuery:"query{provisionTable(' + query.replace(/"/g, '\\"') + '){nodes{provisionId documentNumber patientId provisionType patientFirstName patientLastName district provisionDate staffInitials provisionState}}}"';
    try {
        const response = await requestFromServer
            .getProvisionsInProgress(params);
        dispatch(actions.fetchProvisionsInProgress({ entities: response.data }));
        dispatch(spinnerCreate(false));
    }
    catch (error) {
        dispatch(spinnerCreate(false));
        console.error(error);
    }
}

export const fetchProvisionsPlanned = queryParams => async dispatch => {
    dispatch(spinnerCreate(true));
    let customerId = getCustomerId();
    let params = 'customerId:"' + customerId + '" ';
    params += 'startDate:"' + queryParams.split(' - ')[0].trim() + '" endDate:"' + queryParams.split(' - ')[1].trim() + '"';
    try {
        const response = await requestFromServer
            .getProvisionsPlanned(params);
        dispatch(actions.fetchProvisionsPlanned({ entities: response.data }));
        dispatch(spinnerCreate(false));
    }
    catch (error) {
        dispatch(spinnerCreate(false));
        console.error(error);
    }
}

export const fetchProvisionsFilters = provisionsDate => async dispatch => {
    dispatch(spinnerCreate(true));
    let customerId = getCustomerId();
    let params = 'customerId:"' + customerId + '" ';
    params += 'provisionDateFrom:"' + moment(provisionsDate.split('-')[0].trim(), "DD/MM/YYYY").format("YYYY-MM-DD") + 'T00:00:00Z" provisionDateTo:"' + moment(provisionsDate.split('-')[1].trim(), "DD/MM/YYYY").format("YYYY-MM-DD") + 'T23:59:59Z"';

    try {
        const response = await requestFromServer
            .findProvisionsFilters(params);
        dispatch(actions.provisionsFitlersFetched({ entities: response.data }));
        dispatch(spinnerCreate(false));
    }
    catch (error) {
        dispatch(spinnerCreate(false));
        console.error(error);
    }
}

export const fetchProvisionsTable = (queryParams, pagination, sort, isChangedSort) => async dispatch => {
    dispatch(spinnerCreate(true));

    let params = getProvisionsQueryParams(queryParams, false, pagination, sort, isChangedSort);

    try {
        const response = await requestFromServer
            .findProvisions(params);
        dispatch(actions.provisionsFetched({ entities: response.data, holdPagination: pagination, isChangedSort: isChangedSort }));
        dispatch(spinnerCreate(false));
    }
    catch (error) {
        dispatch(spinnerCreate(false));
        console.error(error);
    }
}

export const fetchProvisionsForFile = (queryParams, callback) => async dispatch => {
    dispatch(spinnerCreate(true));

    let params = getProvisionsQueryParams(queryParams, true);

    try {
        const response = await requestFromServer.findProvisions(params);
        dispatch(actions.provisionsForFileFetched({ entities: response.data, callback }));
        dispatch(spinnerCreate(false));
    }
    catch (error) {
        dispatch(spinnerCreate(false));
        console.error(error);
    }
};

export const fetchProvisionsChart = queryParams => async dispatch => {
    dispatch(spinnerCreate(true));

    let params = getProvisionsQueryParams(queryParams, true);

    params = params.replace(/"/g, '\\"');

    let provisionsQuery = 'provisionTableQuery:"query{provisionTable(' + params + '){nodes{provisionType district provisionDate provisionState}}}"';

    try {
        const response = await requestFromServer.findProvisionsChart(provisionsQuery);
        dispatch(actions.provisionsChartFetched({ entities: response.data }));
        dispatch(spinnerCreate(false));
    }
    catch (error) {
        dispatch(spinnerCreate(false));
        console.error(error);
    }
};

export const fetchProvisionMainSection = id => async dispatch => {
    if (!id) {
        return dispatch(actions.provisionMainInfoFetched({ provisionMainSection: undefined }));
    }
    dispatch(spinnerCreate(true));
    try {
        var pos = id.lastIndexOf('-');
        var newId = id.substring(0, pos) + '/' + id.substring(pos + 1);

        const response = await requestFromServer.getProvisionMainInfoById(newId);
        dispatch(actions.provisionMainInfoFetched({ provisionMainSection: response.data }));
        dispatch(spinnerCreate(false));
    }
    catch (error) {
        console.error(error);
        dispatch(spinnerCreate(false));
    }
}

export const fetchProvision = id => async dispatch => {
    if (!id) {
        return dispatch(actions.provisionFetched({ provisionOtherInfo: undefined }));
    }
    dispatch(spinnerCreate(true));
    try {
        var pos = id.lastIndexOf('-');
        var newId = id.substring(0, pos) + '/' + id.substring(pos + 1);

        const response = await requestFromServer.getProvisionById(newId);
        dispatch(actions.provisionFetched({ provisionOtherInfo: response.data }));
        dispatch(spinnerCreate(false));
    }
    catch (error) {
        console.error(error);
        dispatch(spinnerCreate(false));
    }
}
