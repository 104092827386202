
export function groupBy(array, key) {
    return array.reduce(function (group, object) {
        (group[object[key]] = group[object[key]] || []).push(object);
        return group;
    }, {});
};

export function getTicks(maxValue, tickCount) {
    if (tickCount > maxValue) {
        tickCount = maxValue + 1;
    }

    var niceScale = new NiceScale(0, maxValue, tickCount);

    var upper = niceScale.getNiceUpperBound();

    var spacing = niceScale.getTickSpacing();

    var ticks = [];

    for (var i = 0; spacing * i <= upper; i++) {
        ticks.push(spacing * i);
    }

    return ticks;
}

class NiceScale {
    constructor(lowerBound, upperBound, _maxTicks) {
        var maxTicks = _maxTicks || 10;
        var tickSpacing;
        var range;
        var niceLowerBound;
        var niceUpperBound;

        calculate();

        this.setMaxTicks = function (_maxTicks) {
            maxTicks = _maxTicks;
            calculate();
        };

        this.getNiceUpperBound = function () {
            return niceUpperBound;
        };

        this.getNiceLowerBound = function () {
            return niceLowerBound;
        };

        this.getTickSpacing = function () {
            return tickSpacing;
        };

        function calculate() {
            range = niceNum(upperBound - lowerBound, false);
            tickSpacing = niceNum(range / (maxTicks - 1), true);
            niceLowerBound = Math.floor(lowerBound / tickSpacing) * tickSpacing;
            niceUpperBound = Math.ceil(upperBound / tickSpacing) * tickSpacing;
        }

        function niceNum(range, round) {
            var exponent = Math.floor(Math.log10(range));
            var fraction = range / Math.pow(10, exponent);
            var niceFraction;

            if (round) {
                if (fraction < 1.5)
                    niceFraction = 1;
                else if (fraction < 3)
                    niceFraction = 2;
                else if (fraction < 7)
                    niceFraction = 5;
                else
                    niceFraction = 10;
            } else {
                if (fraction <= 1)
                    niceFraction = 1;
                else if (fraction <= 2)
                    niceFraction = 2;
                else if (fraction <= 5)
                    niceFraction = 5;
                else
                    niceFraction = 10;
            }

            return niceFraction * Math.pow(10, exponent);
        }
    }
}