export class StaffModel {
    constructor() {
        this.Nominative = "";
        this.StaffInitials = "";
        this.Title = "";
        this.BirthDate = "";
        this.IdentityDocument = "";
        this.Id = "";
        this.Assets = "";
        this.District = "";
    }
}