import React, { useMemo, /*useState,*/ useEffect } from "react";
import { Formik, Form } from "formik";
import { isEqual } from "lodash";
import { usePatientsUIContext } from "../PatientsUIContext";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
//import Select from 'react-select';
import { LocaleDatePicker, filterRanges } from "../../../component/datepicker/DateRangePickerConfig";
import DateRangePicker from "react-bootstrap-daterangepicker";
import * as actions from "../../../actions/patients/patientsActions";
import moment from "moment";
import { useRef } from "react";

const prepareFilter = (queryParams, values) => {
    const {
        searchText,
        filterByPeriod,
        filterByInchargeDate,
        filterByDischargeDate,
        filterByStatus,
        filterByGender,
        filterByDistricts,
        //filterByPrescriptor,
        filterByPrescriptions
    } = values;

    const newQueryParams = { ...queryParams };

    const filter = {
        SearchText: searchText,
        Period: filterByPeriod,
        InchargeDate: filterByInchargeDate,
        DischargeDate: filterByDischargeDate,
        Status: filterByStatus,
        Gender: filterByGender,
        District: filterByDistricts,
        //Prescriptor: filterByPrescriptor,
        Prescriptions: filterByPrescriptions
    };

    newQueryParams.filter = filter;

    localStorage.setItem("patientsSelectedFilters-...", JSON.stringify(newQueryParams));

    return newQueryParams;
};

export function PatientsFilters() {
    // Patients UI Context
    const patientsUIContext = usePatientsUIContext();
    const patientsUIProps = useMemo(() => {
        return {
            queryParams: patientsUIContext.queryParams,
            setQueryParams: patientsUIContext.setQueryParams,
        };
    }, [patientsUIContext]);

    const dispatch = useDispatch();

    const applyFilter = (values) => {
        const newQueryParams = prepareFilter(patientsUIProps.queryParams, values);
        if (!isEqual(newQueryParams, patientsUIProps.queryParams)) {
            // if (newQueryParams.filter.InchargeDate !== patientsUIProps.queryParams.filter.InchargeDate || newQueryParams.filter.DischargeDate !== patientsUIProps.queryParams.filter.DischargeDate) {
            //     dispatch(actions.fetchPatientsFilters(newQueryParams.filter.InchargeDate, newQueryParams.filter.DischargeDate));
            // }
            patientsUIProps.setQueryParams(newQueryParams);
        }
    };

    const { currentState } = useSelector(
        (state) => ({ currentState: state.patients }),
        shallowEqual
    );

    const {
        filterPatientsPrescriptions,
        filterPatientStatus,
        filterPatientGender,
        //filterPrescriptor,
        filterDistrict
        //lastAvailableDate,
    } = currentState;

    // const [filterPrescriptorSelectedOption, setfilterPrescriptorSelectedOption] = useState(() => {
    //     let prescriptorSelected = [];
    //     patientsUIProps.queryParams.filter.Prescriptor.forEach(el => {
    //         prescriptorSelected.push({ value: el, label: el });
    //     });
    //     return prescriptorSelected;
    // });

    // useEffect(() => {
    //     if (!patientsUIProps.queryParams.filter.InchargeDate) {
    //         dispatch(actions.getLastAvailableDate());
    //     }
    // }, [dispatch]);

    useEffect(() => {
        if (patientsUIProps.queryParams.filter.InchargeDate || patientsUIProps.queryParams.filter.DischargeDate) {
            let values = {
                searchText: patientsUIProps.queryParams.filter.SearchText,
                filterByPeriod: patientsUIProps.queryParams.filter.Period,
                filterByInchargeDate: patientsUIProps.queryParams.filter.InchargeDate,
                filterByDischargeDate: patientsUIProps.queryParams.filter.DischargeDate,
                filterByStatus: patientsUIProps.queryParams.filter.Status,
                filterByGender: patientsUIProps.queryParams.filter.Gender,
                filterByDistricts: patientsUIProps.queryParams.filter.District,
                //filterByPrescriptor: patientsUIProps.queryParams.filter.Prescriptor,
                filterByPrescriptions: patientsUIProps.queryParams.filter.Prescriptions
            }
            applyFilter(values);
        }
    }, [patientsUIProps.queryParams.filter.InchargeDate, patientsUIProps.queryParams.filter.DischargeDate]);

    useEffect(() => {
        dispatch(actions.fetchPatientsFilters(patientsUIProps.queryParams.filter.InchargeDate, patientsUIProps.queryParams.filter.DischargeDate));
    }, [patientsUIProps.queryParams.filter.InchargeDate, patientsUIProps.queryParams.filter.DischargeDate]);

    // const customSelectStyles = {
    //     control: (base, state) => ({
    //         ...base,
    //         borderColor: state.isFocused ? '#15bbff' : base.borderColor,
    //         '&:hover': { borderColor: '' },
    //         boxShadow: "none",
    //         borderRadius: "0"
    //     })
    // };

    const dischargeDateRef = useRef();
    const inchargeDateRef = useRef();

    const changeStartEndDate = (ref) => {
        ref.current.setStartDate(moment().format("DD/MM/YYYY"));
        ref.current.setEndDate(moment().format("DD/MM/YYYY"));
    }

    return (
        <Formik
            initialValues={{
                searchText: patientsUIProps.queryParams.filter.SearchText,
                filterByPeriod: patientsUIProps.queryParams.filter.Period,
                filterByInchargeDate: patientsUIProps.queryParams.filter.InchargeDate,
                filterByDischargeDate: patientsUIProps.queryParams.filter.DischargeDate,
                filterByStatus: patientsUIProps.queryParams.filter.Status,
                filterByGender: patientsUIProps.queryParams.filter.Gender,
                filterByDistricts: patientsUIProps.queryParams.filter.District,
                //filterByPrescriptor: patientsUIProps.queryParams.filter.Prescriptor,
                filterByPrescriptions: patientsUIProps.queryParams.filter.Prescriptions
            }}
            onSubmit={(values, formikBag) => {
                if (values.filterByInchargeDate !== patientsUIProps.queryParams.filter.InchargeDate || values.filterByDischargeDate !== patientsUIProps.queryParams.filter.DischargeDate) {
                    values = {
                        searchText: "",
                        filterByPeriod: values.filterByPeriod,
                        filterByInchargeDate: values.filterByInchargeDate,
                        filterByDischargeDate: values.filterByDischargeDate,
                        filterByStatus: [],
                        filterByGender: [],
                        filterByDistricts: [],
                        //filterByPrescriptor: [],
                        filterByPrescriptions: []
                    }
                    //setfilterPrescriptorSelectedOption([]);
                    formikBag.setValues(values);
                }
                applyFilter(values);
            }}
        >
            {({
                values,
                handleSubmit,
                handleBlur,
                setFieldValue,
            }) => (
                    <Form onSubmit={handleSubmit} className="form form-label-right filter">
                        <div className="form-group row">
                            <div className="col-lg-12">
                                <input
                                    type="text"
                                    className="form-control"
                                    name="searchText"
                                    placeholder="Cerca"
                                    onBlur={handleBlur}
                                    value={values.searchText}
                                    onChange={(e) => {
                                        setFieldValue("searchText", e.target.value);
                                        setTimeout(() => {
                                            handleSubmit();
                                        }, 1000);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="separator separator-dashed my-3" />
                        <div className="form-group row">
                            <div className="col-lg-12">
                                <h6 className="font-weight-bolder mb-3">Periodo:</h6>
                                <DateRangePicker
                                    initialSettings={{
                                        ranges: filterRanges,
                                        locale: LocaleDatePicker,
                                        linkedCalendars: false,
                                        startDate: values.filterByPeriod.split('-')[0],
                                        endDate: values.filterByPeriod.split('-')[1]
                                    }}
                                    onApply={(event, picker) => {
                                        setFieldValue("filterByPeriod", picker.startDate.format('DD/MM/YYYY') + " - " + picker.endDate.format('DD/MM/YYYY'));
                                        handleSubmit();
                                    }}
                                >
                                    <div className="form-control customPickerDisplay">
                                        <i className="fa fa-calendar" />&nbsp;
                                            <span>{values.filterByPeriod}</span>
                                        <div style={{ float: "right" }}>
                                            <i className="fa fa-caret-down" />
                                        </div>
                                    </div>
                                </DateRangePicker>
                            </div>
                        </div>
                        <div className="separator separator-dashed my-3" />
                        <div className="form-group row">
                            <div className="col-lg-12">
                                <h6 className="font-weight-bolder mb-3">Data Presa In Carico:</h6>
                                <DateRangePicker
                                    ref={inchargeDateRef}
                                    initialSettings={{
                                        ranges: filterRanges,
                                        locale: LocaleDatePicker,
                                        linkedCalendars: false,
                                        startDate: values.filterByInchargeDate ? values.filterByInchargeDate.split('-')[0] : moment().format("DD/MM/YYYY"),
                                        endDate: values.filterByInchargeDate ? values.filterByInchargeDate.split('-')[1] : moment().format("DD/MM/YYYY")
                                    }}
                                    onEvent={(e, picker) => {
                                        if (e.type === "cancel") {
                                            setFieldValue("filterByInchargeDate", "");
                                            changeStartEndDate(inchargeDateRef);
                                            handleSubmit();
                                        } else if (e.type === "apply") {
                                            setFieldValue("filterByInchargeDate", picker.startDate.format('DD/MM/YYYY') + " - " + picker.endDate.format('DD/MM/YYYY'));
                                            handleSubmit();
                                        }
                                    }}
                                >
                                    <div className="form-control customPickerDisplay">
                                        <i className="fa fa-calendar" />&nbsp;
                                            <span>{values.filterByInchargeDate}</span>
                                        {
                                            values.filterByInchargeDate
                                                ? <button type="button" className="react-datepicker__close-icon" style={{ position: "inherit", marginLeft: "10px" }}
                                                    onClick={() => {
                                                        setFieldValue("filterByInchargeDate", "");
                                                        changeStartEndDate(inchargeDateRef);
                                                        handleSubmit();
                                                    }}
                                                />
                                                : null
                                        }
                                        <div style={{ float: "right" }}>
                                            <i className="fa fa-caret-down" />
                                        </div>
                                    </div>
                                </DateRangePicker>
                            </div>
                        </div>
                        <div className="separator separator-dashed my-3" />
                        <div className="form-group row">
                            <div className="col-lg-12">
                                <h6 className="font-weight-bolder mb-3">Data Dismissione:</h6>
                                <DateRangePicker
                                    ref={dischargeDateRef}
                                    initialSettings={{
                                        ranges: filterRanges,
                                        locale: LocaleDatePicker,
                                        linkedCalendars: false,
                                        startDate: values.filterByDischargeDate ? values.filterByDischargeDate.split('-')[0] : moment().format("DD/MM/YYYY"),
                                        endDate: values.filterByDischargeDate ? values.filterByDischargeDate.split('-')[1] : moment().format("DD/MM/YYYY")
                                    }}
                                    onEvent={(e, picker) => {
                                        if (e.type === "cancel") {
                                            setFieldValue("filterByDischargeDate", "");
                                            changeStartEndDate(dischargeDateRef);
                                            handleSubmit();
                                        } else if (e.type === "apply") {
                                            setFieldValue("filterByDischargeDate", picker.startDate.format('DD/MM/YYYY') + " - " + picker.endDate.format('DD/MM/YYYY'));
                                            handleSubmit();
                                        }
                                    }}
                                >
                                    <div className="form-control customPickerDisplay">
                                        <i className="fa fa-calendar" />&nbsp;
                                                <span>{values.filterByDischargeDate}</span>
                                        {
                                            values.filterByDischargeDate !== ""
                                                ? <button type="button" className="react-datepicker__close-icon" style={{ position: "inherit", marginLeft: "10px" }}
                                                    onClick={() => {
                                                        setFieldValue("filterByDischargeDate", "");
                                                        changeStartEndDate(dischargeDateRef);
                                                        handleSubmit();
                                                    }}
                                                />
                                                : null
                                        }
                                        <div style={{ float: "right" }}>
                                            <i className="fa fa-caret-down" />
                                        </div>
                                    </div>
                                </DateRangePicker>
                            </div>
                        </div>
                        {
                            filterPatientStatus && filterPatientStatus.length > 0
                                ? <>
                                    <div className="separator separator-dashed my-3" />
                                    <div className="form-group row">
                                        <div className="col-lg-12">
                                            <h6 className="font-weight-bolder mb-3">Stato del Paziente:</h6>
                                            {
                                                filterPatientStatus.map((el) => {
                                                    return (
                                                        <div key={"div" + el.label}>
                                                            <label
                                                                className="checkbox"
                                                                key={"label" + el.label}
                                                            >
                                                                <input
                                                                    type="checkbox"
                                                                    name="filterByStatus"
                                                                    id={el.label}
                                                                    key={el.label}
                                                                    value={el.value}
                                                                    checked={values.filterByStatus.filter((e) => { return e === el.value }).length > 0 ? true : false}
                                                                    onChange={(e) => {
                                                                        if (e.target.checked) {
                                                                            values.filterByStatus = [
                                                                                ...values.filterByStatus,
                                                                                el.value
                                                                            ];
                                                                        } else {
                                                                            values.filterByStatus = values.filterByStatus.filter((value) => {
                                                                                return value !== el.value;
                                                                            });
                                                                        }
                                                                        handleSubmit();
                                                                    }}
                                                                    onBlur={handleBlur}
                                                                />
                                                                {el.label}
                                                                <span />
                                                            </label>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </>
                                : null
                        }
                        {
                            filterPatientGender && filterPatientGender.length > 0
                                ? <>
                                    <div className="separator separator-dashed my-3" />
                                    <div className="form-group row">
                                        <div className="col-lg-12">
                                            <h6 className="font-weight-bolder mb-3">Sesso:</h6>
                                            {
                                                filterPatientGender.map((el) => {
                                                    return (
                                                        <div key={"div" + el.label}>
                                                            <label
                                                                className="checkbox"
                                                                key={"label" + el.label}
                                                            >
                                                                <input
                                                                    type="checkbox"
                                                                    name="filterByGender"
                                                                    id={el.label}
                                                                    key={el.label}
                                                                    checked={values.filterByGender.filter((e) => { return e === el.value }).length > 0 ? true : false}
                                                                    value={el.value}
                                                                    onChange={(e) => {
                                                                        if (e.target.checked) {
                                                                            values.filterByGender = [
                                                                                ...values.filterByGender,
                                                                                el.value
                                                                            ];
                                                                        } else {
                                                                            values.filterByGender = values.filterByGender.filter((value) => {
                                                                                return value !== el.value;
                                                                            });
                                                                        }
                                                                        handleSubmit();
                                                                    }}
                                                                    onBlur={handleBlur}
                                                                />
                                                                {el.label}
                                                                <span />
                                                            </label>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </>
                                : null
                        }
                        {
                            filterDistrict && filterDistrict.length > 0
                                ? <>
                                    <div className="separator separator-dashed my-3" />
                                    <div className="form-group row">
                                        <div className="col-lg-12" style={{ overflow: "hidden" }}>
                                            <h6 className="font-weight-bolder mb-3">Distretto:</h6>
                                            {
                                                filterDistrict.map((el) => {
                                                    return (
                                                        <div key={"div" + el}>
                                                            <OverlayTrigger
                                                                key={"overlay" + el}
                                                                placement="top"
                                                                overlay={
                                                                    <Tooltip id={`tooltip-top`}>
                                                                        {el}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <label
                                                                    key={"label" + el}
                                                                    className="checkbox"
                                                                >
                                                                    <input
                                                                        type="checkbox"
                                                                        name="filterByDistricts"
                                                                        id={el}
                                                                        key={"input" + el}
                                                                        value={el}
                                                                        checked={values.filterByDistricts.includes(el) ? true : false}
                                                                        onChange={(e) => {
                                                                            if (e.target.checked) {
                                                                                values.filterByDistricts = [
                                                                                    ...values.filterByDistricts,
                                                                                    el
                                                                                ]
                                                                            } else {
                                                                                values.filterByDistricts = values.filterByDistricts.filter((value) => {
                                                                                    return value !== el
                                                                                });
                                                                            }
                                                                            handleSubmit();
                                                                        }}
                                                                        onBlur={handleBlur}
                                                                    />
                                                                    {el}
                                                                    <span />
                                                                </label>
                                                            </OverlayTrigger>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </>
                                : null
                        }
                        {
                            filterPatientsPrescriptions && filterPatientsPrescriptions.length > 0
                                ? <>
                                    <div className="separator separator-dashed my-3" />
                                    <div className="form-group row">
                                        <div className="col-lg-12" style={{ overflow: "hidden" }}>
                                            <h6 className="font-weight-bolder mb-3">Trattamenti:</h6>
                                            {
                                                filterPatientsPrescriptions.map((el) => {
                                                    return (
                                                        <div key={"div" + el}>
                                                            <OverlayTrigger
                                                                key={"overlay" + el}
                                                                placement="top"
                                                                overlay={
                                                                    <Tooltip id={`tooltip-top`}>
                                                                        {el}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <label
                                                                    key={"label" + el}
                                                                    className="checkbox"
                                                                >
                                                                    <input
                                                                        type="checkbox"
                                                                        name="filterByPrescriptions"
                                                                        id={el}
                                                                        key={"input" + el}
                                                                        value={el}
                                                                        checked={values.filterByPrescriptions.includes(el) ? true : false}
                                                                        onChange={(e) => {
                                                                            if (e.target.checked) {
                                                                                values.filterByPrescriptions = [
                                                                                    ...values.filterByPrescriptions,
                                                                                    el
                                                                                ]
                                                                            } else {
                                                                                values.filterByPrescriptions = values.filterByPrescriptions.filter((value) => {
                                                                                    return value !== el
                                                                                });
                                                                            }
                                                                            handleSubmit();
                                                                        }}
                                                                        onBlur={handleBlur}
                                                                    />

                                                                    {el}
                                                                    <span />
                                                                </label>
                                                            </OverlayTrigger>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </>
                                : null
                        }
                    </Form>
                )}
        </Formik>
    );
}
