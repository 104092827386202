import React, { useEffect } from "react";
import { useSubheader } from "../../../../_metronic/layout";
import {
    Card,
    CardBody,
    CardHeader
} from "../../../../_metronic/_partials/controls";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as deviceActions from "../../../actions/devices/devicesActions";
import { DeviceCard } from "./DeviceCard";
import { DeviceLastPatientAssignedTable } from "./DeviceLastPatientAssignedTable";
import { DeviceInfoMenu } from "./DeviceInfoMenu";
import { DeviceLogBookTable } from "./DeviceLogBookTable";
//import { DeviceAccessoriesTable } from "./DeviceAccessoriesTable";

export function DeviceInfoHome({ history, id }) {

    const subheader = useSubheader();

    const dispatch = useDispatch();

    //prendo il dettaglio dispositivo dallo store
    const { deviceDetail } = useSelector(
        (state) => ({
            deviceDetail: state.devices.deviceDetail
        }),
        shallowEqual
    );

    //al caricamento del componente parte la query per prendere il dispositivo dal db
    useEffect(() => {
        dispatch(deviceActions.fetchDevice(id));
    }, [id, dispatch]);

    //setto il titolo della pagina ogni volta che cambia il dispositivo
    useEffect(() => {
        subheader.setTitle(deviceDetail ? `Dispositivi - Dettaglio Dispositivo - '${deviceDetail.BrandAndModel}' Seriale - '${deviceDetail.Serial}'` : "");
    }, [deviceDetail]);

    return (
        <div className="row">
            <div className="col-lg-2">
                <Card className="card-sticky">
                    <CardBody>
                        <DeviceInfoMenu />
                    </CardBody>
                </Card>
            </div>
            <div className="col-lg-10">
                <a name="deviceCard" />
                <Card>
                    <CardHeader title="Scheda Dispositivo" className="font-weight-bolder"/>
                    <CardBody>
                        <DeviceCard deviceDetail={deviceDetail} history={history} />
                    </CardBody>
                </Card>
                <a name="deviceLastPatientAssigned" />
                <Card>
                    <CardHeader title="Ultimo Paziente Assegnatario" className="font-weight-bolder"/>
                    <CardBody>
                        <DeviceLastPatientAssignedTable deviceLastPatient={deviceDetail ? deviceDetail.UltimatePatientAssigned : []} history={history} />
                    </CardBody>
                </Card>
                <a name="deviceLogBook" />
                <Card>
                    <CardHeader title="Libro Macchina" className="font-weight-bolder"/>
                    <CardBody>
                        <DeviceLogBookTable deviceLogBook={deviceDetail ? deviceDetail.LogBook : []} history={history} />
                    </CardBody>
                </Card>
                {/* <a name="deviceAccessories" />
                <Card>
                    <CardHeader title="Accessori" className="font-weight-bolder"/>
                    <CardBody>
                        <DeviceAccessoriesTable devicesAccessories={deviceDetail ? deviceDetail.Accessories : []} history={history} />
                    </CardBody>
                </Card> */}
            </div>
        </div>
    );
}