import React, { useEffect } from "react";
import { useSubheader } from "../../../../_metronic/layout";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
    Input
} from "../../../../_metronic/_partials/controls";
import { Button } from "react-bootstrap";
import KTWizard from "../../../../_metronic/_assets/js/components/wizard";
import "../../../../_metronic/_assets/sass/pages/wizard/wizard-3.scss";
import { Formik, Form, Field } from "formik";
import * as actions from "../../../actions/generic/editActions";

export function PatientPrescriptionEditHome() {
    const suhbeader = useSubheader();

    const { patient, prescriptionForEdit } = useSelector(
        (state) => ({
            patient: state.patients.patientById,
            prescriptionForEdit: state.patients.selectedPrescription
        }),
        shallowEqual
    );

    const dispatch = useDispatch();

    useEffect(() => {
        suhbeader.setTitle(patient ? `Pazienti - Segnalazione prescrizione del paziente - '${patient.FullName}'` : "");
        var options = {
            startStep: 1,
            clickableSteps: true
        }
        new KTWizard('patientPrescriptionWizard', options);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patient]);

    const customLabel = (props) => {
        return (
            <span>
                {props.field.value}
            </span>
        );
    }

    return (
        <>
            {
                prescriptionForEdit
                    ? <Card>
                        <CardHeader title="" >
                            <CardHeaderToolbar>
                                <Button
                                    className="btn btn-secondary font-weight-bold px-9 py-4"
                                    onClick={() => {
                                        //history.push(`/pazienti/${patientById.Id}/dettaglio`)
                                        window.history.back();
                                    }}
                                >
                                    Annulla
                            </Button>
                            </CardHeaderToolbar>
                        </CardHeader>
                        <CardBody>
                            <div id="patientPrescriptionWizard" className="wizard wizard-3" data-wizard-state="step-first" data-wizard-clickable="true">
                                <div className="wizard-nav">
                                    <div className="wizard-steps px-8 py-8 px-lg-15 py-lg-3">
                                        <div className="wizard-step" data-wizard-type="step" data-wizard-state="current">
                                            <div className="wizard-label">
                                                <h3 className="wizard-title">
                                                    <span>1.</span>Generalità</h3>
                                                <div className="wizard-bar"></div>
                                            </div>
                                        </div>
                                        <div className="wizard-step" data-wizard-type="step">
                                            <div className="wizard-label">
                                                <h3 className="wizard-title">
                                                    <span>2.</span>Note</h3>
                                                <div className="wizard-bar"></div>
                                            </div>
                                        </div>
                                        <div className="wizard-step" data-wizard-type="step">
                                            <div className="wizard-label">
                                                <h3 className="wizard-title">
                                                    <span>3.</span>Revisione</h3>
                                                <div className="wizard-bar"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row justify-content-center px-8 px-lg-10 div-custom">
                                    <div className="col-xl-12 col-xxl-7">
                                        <Formik
                                            enableReinitialize={true}
                                            initialValues={prescriptionForEdit}
                                            onSubmit={(values) => {
                                                const model = {
                                                    GenericObject: {
                                                        Id: values.Id,
                                                        Reporting: values.Reporting,
                                                        CustomerId: values.CustomerId,
                                                        Code: values.Code,
                                                        Description: values.Description,
                                                        Section: "Prescrizione del paziente"
                                                    }
                                                }
                                                dispatch(actions.editEntity(model));
                                            }}
                                        >
                                            {({ handleSubmit }) => (
                                                <>
                                                    <Form className="form" onSubmit={handleSubmit}>
                                                        <div className="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                                                            <h4 className="mb-10 font-weight-bold text-dark">Generalità Prescrizione</h4>
                                                            <div className="form-group row">
                                                                <div className="col-lg-3">
                                                                    <Field
                                                                        name="Code"
                                                                        component={Input}
                                                                        label="Codice"
                                                                        disabled
                                                                    />
                                                                </div>
                                                                <div className="col-lg-5">
                                                                    <Field
                                                                        name="Description"
                                                                        component={Input}
                                                                        label="Descrizione"
                                                                        disabled
                                                                    />
                                                                </div>
                                                                <div className="col-lg-2">
                                                                    <Field
                                                                        name="StartDate"
                                                                        component={Input}
                                                                        label="Data inizio"
                                                                        disabled
                                                                    />
                                                                </div>
                                                                <div className="col-lg-2">
                                                                    <Field
                                                                        name="EndDate"
                                                                        component={Input}
                                                                        label="Data fine"
                                                                        disabled
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="pb-5" data-wizard-type="step-content">
                                                            <h4 className="mb-10 font-weight-bold text-dark">Segnalazione</h4>
                                                            <div className="form-group row">
                                                                <div className="col-lg-12">
                                                                    <Field
                                                                        name="Reporting"
                                                                        component="textarea"
                                                                        rows="5"
                                                                        className="form-control"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="pb-5" data-wizard-type="step-content">
                                                            <h4 className="mb-10 font-weight-bold text-dark">Revisione dati inseriti</h4>
                                                            <div className="row">
                                                                <div className="col-lg-12">
                                                                    <h6 className="font-weight-bolder mb-3">Generalità:</h6>
                                                                    <div className="text-dark-50 line-height-lg">
                                                                        <div>
                                                                            <span>Codice: </span>
                                                                            <Field
                                                                                name="Code"
                                                                                component={customLabel}
                                                                            />
                                                                        </div>
                                                                        <div>
                                                                            <span>Descrizione: </span>
                                                                            <Field
                                                                                name="Description"
                                                                                component={customLabel}
                                                                            />
                                                                        </div>
                                                                        <div>
                                                                            <span>Data inizio: </span>
                                                                            <Field
                                                                                name="StartDate"
                                                                                component={customLabel}
                                                                            />
                                                                        </div>
                                                                        <div>
                                                                            <span>Data fine: </span>
                                                                            <Field
                                                                                name="EndDate"
                                                                                component={customLabel}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="separator separator-dashed my-5" />
                                                            <div className="row">
                                                                <div className="col-lg-12">
                                                                    <h6 className="font-weight-bolder mb-3">Richiesta di Modifica:</h6>
                                                                    <div className="text-dark-50 line-height-lg">
                                                                        <div>
                                                                            <Field
                                                                                name="Reporting"
                                                                                component={customLabel}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex justify-content-between border-top mt-5 pt-10">
                                                            <div className="mr-2">
                                                                <button
                                                                    className="btn btn-light-primary font-weight-bold px-9 py-4"
                                                                    data-wizard-type="action-prev"
                                                                >
                                                                    Precedente
														        </button>
                                                            </div>
                                                            <div>
                                                                <button
                                                                    className="btn btn-success font-weight-bold px-9 py-4"
                                                                    data-wizard-type="action-submit"
                                                                    onClick={handleSubmit}
                                                                >
                                                                    Segnala
                                                                </button>
                                                                <button
                                                                    className="btn btn-primary font-weight-bold px-9 py-4"
                                                                    data-wizard-type="action-next"
                                                                >
                                                                    Successivo
														        </button>
                                                            </div>
                                                        </div>
                                                    </Form>
                                                </>
                                            )}
                                        </Formik>
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                    : null
            }
        </>
    );
}