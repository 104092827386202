export class AccessorieModel {
    constructor() {
        this.Id = "";
        this.Photo = "";
        this.BrandAndModel = "";
        this.ItemNumber = "";
        this.Description = "";
        this.AccessoryType = "";
        this.Serial = "";
        this.LotNumber = "";
        this.DeliveryDate = "";
        this.PickUpDate = "";
        this.Assets = [];
    }
}