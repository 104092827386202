// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
import React from "react";
import { PatientStatusCssClasses, PatientStatusTitles } from "../../../pages/patients/PatientsUIHelpers";
import { /*ProvisionStatusCssClasses,*/ ProvisionStatusTitles } from "../../../pages/provisions/ProvisionsUIHelpers"

export function StatusPatientsColumnFormatter(cellContent, row) {
  const getLabelCssClasses = () => {
    return `label label-lg label-light-${PatientStatusCssClasses[row.Status]
      } label-inline`;
  };
  return (
    <span className={getLabelCssClasses()} style={{ width: "max-content" }}>
      {PatientStatusTitles[row.Status]}
    </span>
  );
}

export function ProvisionsStateColumnFormatter(cellContent, row) {
  if (row.Status) {
    return ProvisionStatusTitles[row.Status];
  } else {
    return ProvisionStatusTitles[row.ProvisionState];
  }
  // const getLabelCssClasses = () => {
  //   return `label label-lg label-light-${
  //     ProvisionStatusCssClasses[row.Status]
  //     } label-inline`;
  // };
  // return (
  //   <span className={getLabelCssClasses()} style={{ width: "max-content" }}>
  //     {ProvisionStatusTitles[row.Status]}
  //   </span>
  // );
}

export function CostStatusPatientsColumnFormatter(cellContent, row) {
  const getLabelCssClasses = () => {
    return `label label-lg label-light-${PatientStatusCssClasses[row.PatientStatus]
      } label-inline`;
  };
  return (
    <span className={getLabelCssClasses()}>
      {PatientStatusTitles[row.PatientStatus]}
    </span>
  );
}
