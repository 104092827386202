import React, { useEffect } from "react";
import { useSubheader } from "../../../../_metronic/layout";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import KTWizard from "../../../../_metronic/_assets/js/components/wizard";
import "../../../../_metronic/_assets/sass/pages/wizard/wizard-3.scss";
import { Formik, Form, Field } from "formik";
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar
} from "../../../../_metronic/_partials/controls";
import * as actions from "../../../actions/generic/editActions";

export function ProvisionAssetsEditHome({ id }) {
    const suhbeader = useSubheader();

    const { provisionOtherInfo } = useSelector(
        (state) => ({
            provisionOtherInfo: state.provisions.provisionOtherInfo
        }),
        shallowEqual
    );

    useEffect(() => {
        suhbeader.setTitle(provisionOtherInfo ? `Prestazioni - Segnalazione informazioni dettaglio prestazione - '${id}'` : "");
        var options = {
            startStep: 1,
            clickableSteps: true
        }
        new KTWizard('provisionAssetsWizard', options);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [provisionOtherInfo]);

    const assets = provisionOtherInfo ? provisionOtherInfo.Assets : [];
    const assetsNote = provisionOtherInfo ? provisionOtherInfo.ProvisionNote : "";

    const customLabel = (props) => {
        return (
            <span>
                {props.field.value}
            </span>
        );
    }

    const dispatch = useDispatch();

    return (
        <>
            {
                assets && assets.length > 0
                    ? <Card>
                        <CardHeader title="" >
                            <CardHeaderToolbar>
                                <Button
                                    className="btn btn-secondary font-weight-bold px-9 py-4"
                                    onClick={() => {
                                        window.history.back();
                                    }}
                                >
                                    Annulla
                            </Button>
                            </CardHeaderToolbar>
                        </CardHeader>
                        <CardBody>
                            <div id="provisionAssetsWizard" className="wizard wizard-3" data-wizard-state="step-first" data-wizard-clickable="true">
                                <div className="wizard-nav">
                                    <div className="wizard-steps px-8 py-8 px-lg-15 py-lg-3">
                                        <div className="wizard-step" data-wizard-type="step" data-wizard-state="current">
                                            <div className="wizard-label">
                                                <h3 className="wizard-title">
                                                    <span>1.</span>Generalità</h3>
                                                <div className="wizard-bar"></div>
                                            </div>
                                        </div>
                                        <div className="wizard-step" data-wizard-type="step">
                                            <div className="wizard-label">
                                                <h3 className="wizard-title">
                                                    <span>2.</span>Note</h3>
                                                <div className="wizard-bar"></div>
                                            </div>
                                        </div>
                                        <div className="wizard-step" data-wizard-type="step">
                                            <div className="wizard-label">
                                                <h3 className="wizard-title">
                                                    <span>3.</span>Revisione</h3>
                                                <div className="wizard-bar"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row justify-content-center px-8 px-lg-10 div-custom">
                                    <div className="col-xl-12 col-xxl-7">
                                        <Formik
                                            enableReinitialize={true}
                                            initialValues={
                                                {
                                                    ProvisionNote: assetsNote,
                                                    CustomerId: provisionOtherInfo.CustomerId,
                                                    ProvisionId: provisionOtherInfo.Id,
                                                    Reporting: provisionOtherInfo.Reporting
                                                }
                                            }
                                            onSubmit={(values) => {
                                                const model = {
                                                    GenericObject: {
                                                        ProvisionId: values.ProvisionId,
                                                        Reporting: values.Reporting,
                                                        CustomerId: values.CustomerId,
                                                        ProvisionNote: values.ProvisionNote,
                                                        Section: "Informazioni dettaglio prestazione"
                                                    }
                                                }
                                                dispatch(actions.editEntity(model));
                                            }}
                                        >
                                            {({ handleSubmit }) => (
                                                <>
                                                    <Form className="form" onSubmit={handleSubmit}>
                                                        <div className="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                                                            <h4 className="mb-10 font-weight-bold text-dark">Generalità</h4>
                                                            {
                                                                assets.map((el, index) => {
                                                                    return (
                                                                        <div key={"divInput" + index} className="form-group row">
                                                                            <div key={"div" + index} className="col-lg-12">
                                                                                <input key={"input" + index} type="text" className="form-control" disabled value={el.AssetTypeDescription} />
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                            <div className="form-group row">
                                                                <div className="col-lg-12">
                                                                    <label>Note</label>
                                                                    <Field
                                                                        name="ProvisionNote"
                                                                        component="textarea"
                                                                        rows="5"
                                                                        className="form-control"
                                                                        disabled
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="pb-5" data-wizard-type="step-content">
                                                            <h4 className="mb-10 font-weight-bold text-dark">Segnalazione</h4>
                                                            <div className="form-group row">
                                                                <div className="col-lg-12">
                                                                    <Field
                                                                        name="Reporting"
                                                                        component="textarea"
                                                                        rows="5"
                                                                        className="form-control"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="pb-5" data-wizard-type="step-content">
                                                            <h4 className="mb-10 font-weight-bold text-dark">Revisione dati inseriti</h4>
                                                            <div className="row">
                                                                <div className="col-lg-12">
                                                                    <h6 className="font-weight-bolder mb-3">Generalità:</h6>
                                                                    <div className="text-dark-50 line-height-lg">
                                                                        {
                                                                            assets.map((el, index) => {
                                                                                return (
                                                                                    <div key={"divRevision" + index}>
                                                                                        <span>{el.AssetTypeDescription}</span>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                        <div>
                                                                            <Field
                                                                                name="ProvisionNote"
                                                                                component={customLabel}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="separator separator-dashed my-5" />
                                                            <div className="row">
                                                                <div className="col-lg-12">
                                                                    <h6 className="font-weight-bolder mb-3">Richiesta di Modifica:</h6>
                                                                    <div className="text-dark-50 line-height-lg">
                                                                        <div>
                                                                            <Field
                                                                                name="Reporting"
                                                                                component={customLabel}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex justify-content-between border-top mt-5 pt-10">
                                                            <div className="mr-2">
                                                                <button
                                                                    className="btn btn-light-primary font-weight-bold px-9 py-4"
                                                                    data-wizard-type="action-prev"
                                                                >
                                                                    Precedente
														        </button>
                                                            </div>
                                                            <div>
                                                                <button
                                                                    className="btn btn-success font-weight-bold px-9 py-4"
                                                                    data-wizard-type="action-submit"
                                                                    onClick={handleSubmit}
                                                                >
                                                                    Segnala
                                                                </button>
                                                                <button
                                                                    className="btn btn-primary font-weight-bold px-9 py-4"
                                                                    data-wizard-type="action-next"
                                                                >
                                                                    Successivo
														        </button>
                                                            </div>
                                                        </div>
                                                    </Form>
                                                </>
                                            )}
                                        </Formik>
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                    : null
            }
        </>
    );
}