import React, { useEffect, useMemo, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { defaultDevicesTableSorted } from "../DevicesUIHelpers";
import {
    sortCaret,
    headerSortingClasses,
} from "../../../../_metronic/_helpers";
import { useDevicesUIContext } from "../DevicesUIContext";
import * as actions from "../../../actions/devices/devicesActions";
import { tableSortByDate } from "../../../component/table/tableSort";
import { InfiniteScrollTable } from "../../../component/table/InfiniteScrollTable";
import { ExportButton } from "../../../component/exportbutton/ExportButton";
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar
} from "../../../../_metronic/_partials/controls";

export function DevicesTable() {
    const dispatch = useDispatch();

    //prendo lo stato iniziale dei filtri dal context e li memorizzo in devicesUIProps
    const devicesUIContext = useDevicesUIContext();
    const devicesUIProps = useMemo(() => {
        return {
            queryParams: devicesUIContext.queryParams,
            openInfoDevicePage: devicesUIContext.openInfoDevicePage,
        };
    }, [devicesUIContext]);

    //prendo la lista dei dispositivi dallo store
    const { currentState } = useSelector(
        (state) => ({ currentState: state.devices }),
        shallowEqual
    );

    const { devicesList, pagination } = currentState;

    //salvo nello stato del componente la riga selezionata che mi serve per il download del file
    const [selectedRows, setSelectedRows] = useState([]);
    //salvo nello stato del componente il sort della tabella che mi serve per il passarlo alla funzione per scaricare i dati dal db
    const [sortTable, setSortTable] = useState(undefined);

    //al caricamento del componente parte la chiamate per scaricare i dati, questa chiamata parte ogni volta che cambiano i filtri
    useEffect(() => {
        if (sortTable) {
            dispatch(actions.fetchDevices(devicesUIProps.queryParams, null, sortTable));
        }
    }, [devicesUIProps.queryParams, dispatch]);

    const columns = [
        {
            dataField: "DeviceType",
            text: "Tipo Dispositivo",
            sort: true,
            align: "center",
            headerAlign: 'center',
            sortCaret: sortCaret,
            headerSortingClasses
        },
        {
            dataField: "BrandAndModel",
            text: "Descrizione",
            sort: true,
            align: "center",
            headerAlign: 'center',
            sortCaret: sortCaret,
            headerSortingClasses
        },
        {
            dataField: "Serial",
            text: "Seriale",
            sort: true,
            align: "center",
            headerAlign: 'center',
            sortCaret: sortCaret,
            headerSortingClasses
        },
        // {
        //     dataField: "Lot",
        //     text: "Lotto",
        //     sort: true,
        //     align: "center",
        //     headerAlign: 'center',
        //     sortCaret: sortCaret,
        //     headerSortingClasses
        // },
        {
            dataField: "TotalHoursOfUse",
            text: "Ore D'Uso Complessive",
            sort: true,
            align: "center",
            headerAlign: 'center',
            sortCaret: sortCaret,
            headerSortingClasses
        },
        {
            dataField: "LastPatientAssigned",
            text: "Ultimo Paziente Assegnatario",
            sort: true,
            align: "center",
            headerAlign: 'center',
            sortCaret: sortCaret,
            headerSortingClasses
        },
        {
            dataField: "DeliveryDate",
            text: "Data Consegna",
            sort: true,
            align: "center",
            headerAlign: 'center',
            sortCaret: sortCaret,
            headerSortingClasses,
            sortFunc: tableSortByDate
        },
        {
            dataField: "PickUpDate",
            text: "Data Ritiro",
            sort: true,
            align: "center",
            headerAlign: 'center',
            sortCaret: sortCaret,
            headerSortingClasses,
            sortFunc: tableSortByDate
        },
    ];

    return (
        <Card className="customHeaderPadding">
            <CardHeader title="Lista Dispositivi" className="font-weight-bolder">
                <CardHeaderToolbar>
                    <ExportButton
                        data={selectedRows.length > 0 ? selectedRows : []}
                        loadData={(callback) => dispatch(actions.fetchDevicesForFile(devicesUIProps.queryParams, callback))}
                        columns={columns}
                        fileName={"dispositivi"}
                    />
                </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
                <InfiniteScrollTable
                    keyField={"RowNumber"}
                    id={"deviceTable"}
                    columns={columns}
                    data={devicesList}
                    defaultSorted={defaultDevicesTableSorted}
                    pagination={pagination}
                    onSelectRow={devicesUIProps.openInfoDevicePage}
                    onSelectCheckBox={(row, isSelect) => {
                        if (isSelect) {
                            setSelectedRows([...selectedRows, row]);
                        } else {
                            setSelectedRows(selectedRows.filter((el) => { return el.Id !== row.Id }));
                        }
                    }}
                    hoverEnable={true}
                    loadData={(event, tableInfo) => {
                        //tableinfo è undefined quando parte l'infinite scroll della tabella
                        if (tableInfo) {
                            setSortTable({ dataField: tableInfo.sortField, order: tableInfo.sortOrder });
                            dispatch(actions.fetchDevices(devicesUIProps.queryParams, pagination, { dataField: tableInfo.sortField, order: tableInfo.sortOrder }, true));
                        } else {
                            dispatch(actions.fetchDevices(devicesUIProps.queryParams, pagination, sortTable, false));
                        }
                    }}
                    noData={"Non sono presenti dati"}
                />
            </CardBody>
        </Card>
    );
}
