import React from "react";
import { ProvisionsTypeTitles } from "../../../pages/provisions/ProvisionsUIHelpers"

export function ProvisionLinkColumnFormatter(cellContent, row) {
  return (
    <span>
      <a href={`/prestazioni/${row.ProvisionId}/dettaglio`}>{ProvisionsTypeTitles[row.ProvisionType]}</a>
    </span>
  );
}

export function PatientLinkColumnFormatter(cellContent, row){
    return(
        <span>
            <a href={`/pazienti/${row.PatientId}/dettaglio`}>{row.PatientFullName}</a>
        </span>
    );
}