import React from "react";
import { Chart } from "react-google-charts";
import { getTicks } from "../../../utils/utility";

export function PatientChart({ patientChartData, patientProvisionsNear }) {
    function charts() {
        var graphs = [];

        var colors = ['#F7A67E', '#5D62B5', '#29C3BE', '#F2726F'];

        var colorIndex = 0;

        var chartIndex = 0;
        for (const prescription in patientChartData) {
            let dataTable = [["Mese/Anno", "Giorni"]];
            let maxValue = 0;

            patientChartData[prescription].forEach((el) => {
                dataTable.push([el.MonthYearName, el.Count]);
                if (el.Count > maxValue) {
                    maxValue = el.Count;
                }
            });

            var graphColors = [];

            if (colors[colorIndex]) {
                graphColors = [colors[colorIndex]];
            } else {
                colorIndex = 0;
                graphColors = [colors[colorIndex]];
            }

            let vticks = getTicks(maxValue, 4);

            const chartOptions = {
                colors: graphColors,
                fontName: "LindeDaxReg",
                fontSize: 14,
                title: prescription,
                titleTextStyle: {
                    italic: false,
                    fontSize: 15,
                    color: "#5A5A5A"
                },
                chartArea: {
                    width: "100%",
                    left: 60,
                    top: 40,
                    bottom: 70
                },
                bar: {
                    groupWidth: 40
                },
                hAxis: {
                    textStyle: {
                        italic: false,
                        color: "#5A5A5A"
                    }
                },
                vAxis: {
                    title: "Giorni",
                    ticks: vticks,
                    format: "#",
                    viewWindow: {
                        min: 0
                    },
                    titleTextStyle: {
                        italic: false,
                        color: "#999"
                    },
                    textStyle: {
                        italic: false,
                        color: "#5A5A5A"
                    },
                    baselineColor: "#F2F2F2",
                    gridlines: {
                        color: "#F2F2F2"
                    }
                },
                legend: {
                    position: "none",
                }
            };

            graphs.push(
                <Chart chartType="ColumnChart" height={200} data={dataTable} options={chartOptions} key={"chart" + chartIndex} />
            );

            colorIndex++;
            chartIndex++;
        }

        return graphs;
    }

    return (
        <>
            {
                patientChartData
                    ? <>
                        {
                            charts()
                        }
                        <div className="mt-8">
                            <div className="d-flex align-items-center">
                                <div className="col-xl-2 col-lg-2 d-flex align-items-center mr-5 my-1">
                                    <div className="d-flex flex-column">
                                        <span className="font-size-h6">Ultima Prestazione Svolta</span>
                                        <span className="font-size-sm">{patientProvisionsNear ? patientProvisionsNear.LastProvisionType : ""}</span>
                                    </div>
                                </div>
                                <div className="col-xl-2 col-lg-2 d-flex align-items-center mr-5 my-1">
                                    <div className="d-flex flex-column">
                                        <span className="font-size-h6">Data</span>
                                        <span className="font-size-sm">{patientProvisionsNear ? patientProvisionsNear.LastProvisionDate : ""}</span>
                                    </div>
                                </div>
                                <div className="col-xl-2 col-lg-2 d-flex align-items-center mr-5 my-1">
                                    <div className="d-flex flex-column">
                                        <span className="font-size-h6">Esito</span>
                                        <span className="font-size-sm">{patientProvisionsNear ? patientProvisionsNear.LastProvisionState : ""}</span>
                                    </div>
                                </div>
                                <div className="col-xl-2 col-lg-2 d-flex align-items-center mr-5 my-1">
                                    <div className="d-flex flex-column">
                                        <span className="font-size-h6">Prossima Prestazione Prevista</span>
                                        <span className="font-size-sm">{patientProvisionsNear ? patientProvisionsNear.NextProvisionType : ""}</span>
                                    </div>
                                </div>
                                <div className="col-xl-2 col-lg-2 d-flex align-items-center mr-5 my-1">
                                    <div className="d-flex flex-column">
                                        <span className="font-size-h6">Data</span>
                                        <span className="font-size-sm">{patientProvisionsNear ? patientProvisionsNear.NextProvisionDate : ""}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                    : <span>Non sono presenti dati</span>
            }
        </>
    );
}
