import { SweetAlert, modalMessageType, modalIconType } from "../../component/alert/SweetAlert";
import { spinnerCreate } from "../spinner/spinnerActions";
import * as requestFromServer from "./editCrud";
import store from "../../../redux/store";
import { companies } from "../../utils/constant";

export const editEntity = object => dispatch => {
  if (object.GenericObject.Reporting === "") {
    SweetAlert(modalMessageType.fieldRequired.replace("{0}", "Segnalazione"), modalIconType.warning);
    return;
  }

  let authUser = store.getState().auth.user;
  let customerId = localStorage.getItem("customerId");

  const userForEditMail = {
    FullName: authUser.fullname,
    CompanyName: companies.find(a => a.CustomerId === customerId).CustomerName,
    Email: authUser.username
  }

  object.User = userForEditMail;

  dispatch(spinnerCreate(true));
  requestFromServer.editEntity(object).then((result) => {
    if (result.status == 200) {
      dispatch(spinnerCreate(false));
      SweetAlert(modalMessageType.requesteditOK, modalIconType.success);
    } else {
      dispatch(spinnerCreate(false));
      SweetAlert(modalMessageType.requesteditKO, modalIconType.error);
    }
  }, (error) => {
    dispatch(spinnerCreate(false));
    SweetAlert(modalMessageType.requesteditKO, modalIconType.error);
  });
}