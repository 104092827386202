import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "../../../assets/css/table.css";
import { PatientPrescriptionsActionTableColumnFormatter } from "../../../component/table/column-formatters/ActionsColumnFormatter";
import { useDispatch } from "react-redux";
import {
    sortCaret,
    headerSortingClasses,
} from "../../../../_metronic/_helpers";
import { defaultPatientPrescriptionsSorted } from "./PatientInfoUIHelpers";
import { tableSortByDate } from "../../../component/table/tableSort";


export function PatientPrescriptions({ prescriptions, history }) {

    const dispatch = useDispatch();

    const columns = [
        {
            dataField: "Code",
            text: "Codice",
            sort: true,
            align: "center",
            headerAlign: 'center',
            sortCaret: sortCaret,
            headerSortingClasses
        },
        {
            dataField: "Description",
            text: "Descrizione",
            sort: false,
            align: "center",
            headerAlign: 'center'
        },
        {
            dataField: "StartDate",
            text: "Data inizio",
            sort: true,
            align: "center",
            headerAlign: 'center',
            sortCaret: sortCaret,
            headerSortingClasses,
            sortFunc: tableSortByDate,
        },
        {
            dataField: "EndDate",
            text: "Data fine",
            sort: true,
            align: "center",
            headerAlign: 'center',
            sortCaret: sortCaret,
            headerSortingClasses,
            sortFunc: tableSortByDate,
        },
        {
            isDummyField: true,
            dataField: "",
            text: "Azioni",
            align: "center",
            headerAlign: 'center',
            formatter: PatientPrescriptionsActionTableColumnFormatter,
            formatExtraData: {
                history,
                dispatch,
                title: "Invia segnalazione"
            },
            classes: "pr-0",
            headerClasses: "pr-3",
        },
    ];

    return (
        <BootstrapTable
            wrapperClasses="table-responsive"
            bordered={false}
            classes="table-head-custom table-vertical-center"
            bootstrap4
            keyField="Id"
            data={prescriptions}
            columns={columns}
            noDataIndication={"Non sono presenti dati"}
            defaultSorted={defaultPatientPrescriptionsSorted}
        >
        </BootstrapTable>
    );
}