import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
//import { initialProvisionFilter } from "./ProvisionsUIHelpers";
import moment from "moment";

const ProvisionsUIContext = createContext();

export function useProvisionsUIContext() {
    return useContext(ProvisionsUIContext);
}

export function ProvisionsUIProvider({ provisionsUIEvents, children }) {

    let provisionsSelectedFilter = JSON.parse(localStorage.getItem('provisionsSelectedFilters-...'));

    if (!provisionsSelectedFilter) {
        provisionsSelectedFilter = {
            filter: {
                SearchText: "",
                ProvisionsDate: moment().subtract(1, "month").format('DD/MM/YYYY') + " - " + moment().format('DD/MM/YYYY'),
                District: [],
                Status: [],
                Type: []
            },
        };
    }

    const [queryParams, setQueryParamsBase] = useState(provisionsSelectedFilter);
    const setQueryParams = useCallback(nextQueryParams => {
        setQueryParamsBase(prevQueryParams => {
            if (isFunction(nextQueryParams)) {
                nextQueryParams = nextQueryParams(prevQueryParams);
            }

            if (isEqual(prevQueryParams, nextQueryParams)) {
                return prevQueryParams;
            }
            return nextQueryParams;
        });
    }, []);

    const value = {
        queryParams,
        setQueryParamsBase,
        setQueryParams,
        openInfoProvisionPage: provisionsUIEvents.openInfoProvisionPage
    };

    return <ProvisionsUIContext.Provider value={value}>{children}</ProvisionsUIContext.Provider>;
}