export class DeviceTableModel {
    constructor() {
        this.DeviceType = "";
        this.BrandAndModel = "";
        this.Serial = "";
        //this.Lot = "";
        this.TotalHoursOfUse = "";
        this.LastPatientAssigned = "";
        this.DeliveryDate = "";
        this.PickUpDate = "";
        this.Id = "";
        this.District = "";
        this.RowNumber = 0;
    }
}