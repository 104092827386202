export class PatientsTableModel {
    constructor() {
        this.Id = "";
        this.Nominative = "";
        this.Gender = 0;
        this.FiscalCode = "";
        this.District = "";
        this.Status = 0;
        this.Prescriptions = "";
        this.Provision = 0;
        this.PrescriptionId = "";
        //this.Prescriptor = "";
        this.InchargeDate = "";
        this.DischargeDate = "";
        this.TherapyDays = 0;
    }
}