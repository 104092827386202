import { createUserManager } from 'redux-oidc';

const settings = {
    //authority: "https://localhost:44315",
    authority: process.env.REACT_APP_AUTORITY,
    client_id: process.env.REACT_APP_CLIENT_ID,
    //client_id: "LSI_Client_Dev",
    redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/callback`,
    response_type: "code",
    scope: "openid profile LSI LSIDomains apps", // "offline_access", DA RIPRISTINARE, RICHIEDE PRIMA CONFIGURAZIONE DEL CLIENT SU DB
    post_logout_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/logout`,
    silent_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/silent_renew`,
    automaticSilentRenew: true,
};

// const settings = {
//     //authority: "https://localhost:44315",
//     authority: "https://identity.lindemedicale.net",
//     //client_id: "LSIjs_local_VP",
//     client_id: "LSI_Client_Prod",
//     redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/callback`,
//     response_type: "code",
//     scope: "openid profile LSI LSIDomains",
//     post_logout_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/logout`,
//     silent_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/silent_renew.html`,
//     automaticSilentRenew: true,
// };

const userManager = createUserManager(settings);

export default userManager;
