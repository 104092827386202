import React, { createContext, useContext } from "react";

const ProvisionInfoUIContext = createContext();

export function useProvisionInfoUIContext() {
    return useContext(ProvisionInfoUIContext);
}

export function ProvisionInfoUIProvider({ children }) {

    return <ProvisionInfoUIContext.Provider>{children}</ProvisionInfoUIContext.Provider>;
}