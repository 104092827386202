import React from "react";
import {
    sortCaret,
    headerSortingClasses,
} from "../../../../_metronic/_helpers";
import BootstrapTable from "react-bootstrap-table-next";
import { defaultPatientMedicinalsSorted } from "./PatientInfoUIHelpers"
import { AssetsColumnFormatter, /*FotoColumnFormatter,*/ MedicinalsFotoColumnFormatter } from "../../../component/table/column-formatters/ActionsColumnFormatter";
import { tableSortByDate } from "../../../component/table/tableSort";

export function PatientMedicinalsTable({ medicinals }) {

    const columns = [
        {
            isDummyField: true,
            dataField: "Foto",
            text: "",
            align: "center",
            headerAlign: 'center',
            //formatter: FotoColumnFormatter,
            formatter: MedicinalsFotoColumnFormatter, // soluzione momentanea in quanto a volte arrivano foto 'rotte' dal db appena sistemato rimettere quello sopra
        },
        {
            dataField: "Brand",
            text: "Brand",
            sort: true,
            align: "center",
            headerAlign: 'center',
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        {
            dataField: "Name",
            text: "Nome",
            sort: true,
            align: "center",
            headerAlign: 'center',
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        {
            dataField: "Description",
            text: "Descrizione",
            sort: false,
            align: "center",
            headerAlign: 'center',
        },
        {
            dataField: "Serial",
            text: "Seriale",
            sort: false,
            align: "center",
            headerAlign: 'center',
        },
        {
            dataField: "LotNumber",
            text: "Lotto",
            sort: false,
            align: "center",
            headerAlign: 'center',
        },
        {
            dataField: "DeliveryDate",
            text: "Data Consegna",
            sort: true,
            align: "center",
            headerAlign: 'center',
            sortFunc: tableSortByDate,
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        {
            isDummyField: true,
            dataField: "",
            text: "Documentazione",
            align: "center",
            headerAlign: 'center',
            formatter: AssetsColumnFormatter,
        },
    ];
    return (
        <BootstrapTable
            wrapperClasses="table-responsive"
            bordered={false}
            classes="table table-head-custom table-vertical-center"
            bootstrap4
            keyField="Serial"
            data={medicinals === null ? [] : medicinals}
            columns={columns}
            defaultSorted={defaultPatientMedicinalsSorted}
            noDataIndication={"Non sono presenti dati"}
        >
        </BootstrapTable>
    );
}