import React from "react";
import {
    sortCaret,
    headerSortingClasses,
} from "../../../../_metronic/_helpers";
import BootstrapTable from "react-bootstrap-table-next";
import { defaultPatientConsumablesSorted } from "./PatientInfoUIHelpers"
import { AssetsColumnFormatter, /*FotoColumnFormatter,*/ ConsumablesFotoColumnFormatter } from "../../../component/table/column-formatters/ActionsColumnFormatter";
import { tableSortByDate } from "../../../component/table/tableSort";

export function PatientConsumablesTable({ consumables }) {

    const columns = [
        {
            isDummyField: true,
            dataField: "Photo",
            text: "",
            align: "center",
            headerAlign: 'center',
            //formatter: FotoColumnFormatter,
            formatter: ConsumablesFotoColumnFormatter, // soluzione momentanea in quanto a volte arrivano foto 'rotte' dal db appena sistemato rimettere quello sopra
        },
        {
            dataField: "ConsumableType",
            text: "Tipologia",
            sort: true,
            align: "center",
            headerAlign: 'center',
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        // {
        //     dataField: "BrandAndModel",
        //     text: "Marca e Modello",
        //     sort: true,
        //     align: "center",
        //     headerAlign: 'center',
        //     sortCaret: sortCaret,
        //     headerSortingClasses,
        // },
        {
            dataField: "Description",
            text: "Descrizione",
            sort: false,
            align: "center",
            headerAlign: 'center',
        },
        {
            dataField: "Serial",
            text: "Codice Articolo",
            sort: false,
            align: "center",
            headerAlign: 'center',
        },
        {
            dataField: "LotNumber",
            text: "Lotto",
            sort: false,
            align: "center",
            headerAlign: 'center',
        },
        {
            dataField: "Quantity",
            text: "Quantità",
            sort: true,
            align: "center",
            headerAlign: 'center',
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        {
            dataField: "DeliveryDate",
            text: "Data Consegna",
            sort: true,
            align: "center",
            headerAlign: 'center',
            sortFunc: tableSortByDate,
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        {
            isDummyField: true,
            dataField: "Assets",
            text: "Documentazione",
            align: "center",
            headerAlign: 'center',
            formatter: AssetsColumnFormatter,
        },
    ];
    return (
        <BootstrapTable
            wrapperClasses="table-responsive"
            bordered={false}
            classes="table table-head-custom table-vertical-center"
            bootstrap4
            keyField="Serial"
            data={consumables === null ? [] : consumables}
            columns={columns}
            defaultSorted={defaultPatientConsumablesSorted}
            noDataIndication={"Non sono presenti dati"}
        >
        </BootstrapTable>
    );
}