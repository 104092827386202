import React, { createContext, useContext } from "react";

const DeviceInfoUIContext = createContext();

export function useDeviceInfoUIContext() {
    return useContext(DeviceInfoUIContext);
}

export function DeviceInfoUIProvider({ children }) {

    return <DeviceInfoUIContext.Provider>{children}</DeviceInfoUIContext.Provider>;
}