export class LogBookModel {
    constructor() {
        this.Id = "";
        this.Date = "";
        this.Type = "";
        this.ProvisionId = "";
        this.ProvisionType = "";
        this.PatientId = "";
        this.PatientFullName = "";
        this.District = "";
        this.StaffInitials = "";
        this.Reporting = "";
        this.CustomerId = "";
    }
}