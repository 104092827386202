import { persistReducer } from "redux-persist";
import storage from 'redux-persist/lib/storage/session';

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API"
};

const initialAuthState = {
  user: undefined,
  authToken: undefined
};

export const reducer = persistReducer(
  { storage, key: "auth", whitelist: ["user", "authToken"] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { data } = action.payload;

        let user = {
          username: data.entities.profile.preferred_username,
          email: data.entities.profile.preferred_username,
          available_app: data.entities.profile.available_app,
          associatedCustomersId: data.entities.profile.associatedCustomersId.split(','),
          fullname: data.entities.profile.name,
          firstname: data.entities.profile.given_name,
          lastname: data.entities.profile.family_name
        }
        localStorage.setItem("customerId", user.associatedCustomersId[0]);

        return { authToken: data.entities.access_token, user };
      }

      case actionTypes.Logout: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        localStorage.removeItem("customerId");
        return initialAuthState;
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: data => (
    {
      type: actionTypes.Login,
      payload: { data }
    }
  ),
  logout: () => ({ type: actionTypes.Logout }),
};
