export const defaultPatientPrescriptionsSorted = [{ dataField: "StartDate", order: "desc" }];

export const defaultPatientProvisionsSorted = [{ dataField: "ProvisionDate", order: "desc" }];

export const defaultPatientDevicesSorted = [{ dataField: "DeliveryDate", order: "desc" }];

export const defaultPatientAccessoriesSorted = [{ dataField: "DeliveryDate", order: "desc" }];

export const defaultPatientConsumablesSorted = [{ dataField: "DeliveryDate", order: "desc" }];

export const defaultPatientMedicinalsSorted = [{ dataField: "DeliveryDate", order: "desc" }];

export const defaultPatientInvoicesSorted = [{ dataField: "MonthNumber", order: "desc" }];