import React, { useEffect, useState } from "react";
import { useSubheader } from "../../../../_metronic/layout";
import {
    Card,
    CardBody,
    CardHeader
} from "../../../../_metronic/_partials/controls";
import { ProvisionsTable } from "./ProvisionsTable";
import { ProvisionsChart } from "./ProvisionsChart";
import { ProvisionsFilters } from "./ProvisionsFilters";
import { PlannedProvisions } from "./PlannedProvisions";
import { ProvisionsInProgressTable } from "./ProvisionsInProgressTable";
import { useProvisionsUIContext } from "../ProvisionsUIContext";
import { KTScrollFilters } from "../../../component/scroll/Scroll";

export const ProvisionsHome = () => {
    const suhbeader = useSubheader();
    suhbeader.setTitle("Prestazioni");

    const provisionsUIContext = useProvisionsUIContext();

    const [titleProvisionsInProgress, setTitleProvisionsInProgress] = useState("");

    useEffect(() => {
        let provisionsDate = provisionsUIContext.queryParams.filter.ProvisionsDate;
        setTitleProvisionsInProgress('Prestazioni dal ' + provisionsDate.split('-')[0].trim() + ' al ' + provisionsDate.split('-')[1].trim());
        KTScrollFilters.init("provisionsFilter")
    }, [provisionsUIContext.queryParams]);

    return (
        <div className="row">
            <div className="col-lg-2">
                <Card className="card-sticky" id="provisionsFilter">
                    <CardHeader title="Filtri" className="font-weight-bolder">
                    </CardHeader>
                    <CardBody className="offcanvas-content">
                        <ProvisionsFilters />
                    </CardBody>
                </Card>
            </div>
            <div className="col-lg-10 card-lg-10">
                <Card>
                    <CardHeader title="Prestazioni Pianificate" className="font-weight-bolder" />
                    <CardBody>
                        <PlannedProvisions />
                    </CardBody>
                </Card>
                <Card>
                    <CardHeader title="Grafico Prestazioni Svolte" className="font-weight-bolder" />
                    <CardBody>
                        <ProvisionsChart />
                    </CardBody>
                </Card>
                <Card>
                    <CardHeader title={titleProvisionsInProgress} className="font-weight-bolder" />
                    <CardBody>
                        <ProvisionsInProgressTable />
                    </CardBody>
                </Card>
                <ProvisionsTable />
            </div>
        </div>
    );
};
