import * as requestFromServer from "./patientsCrud";
import { patientsSlice } from "./patientsSlice";
import { spinnerCreate } from "../spinner/spinnerActions";
import moment from "moment";

const { actions } = patientsSlice;

function getCustomerId() {
    return localStorage.getItem("customerId");
}

export const fetchPatientsFilters = (inchargeDate, dischargeDate) => async dispatch => {
    dispatch(spinnerCreate(true));
    let customerId = getCustomerId();
    let params = 'customerId:"' + customerId + '" ';

    if (inchargeDate) {
        params += 'inchargeDateFrom:"' + moment(inchargeDate.split('-')[0].trim(), "DD/MM/YYYY").format("YYYY-MM-DD") + 'T00:00:00Z" inchargeDateTo:"' + moment(inchargeDate.split('-')[1].trim(), "DD/MM/YYYY").format("YYYY-MM-DD") + 'T23:59:59Z"';
    }

    if (dischargeDate) {
        params += ' dischargeDateFrom:"' + moment(dischargeDate.split('-')[0].trim(), "DD/MM/YYYY").format("YYYY-MM-DD") + 'T00:00:00Z" dischargeDateTo:"' + moment(dischargeDate.split('-')[1].trim(), "DD/MM/YYYY").format("YYYY-MM-DD") + 'T23:59:59Z"'
    }

    try {
        const response = await requestFromServer
            .findPatientsFilters(params);
        dispatch(actions.patientsFitlersFetched({ entities: response.data }));
        dispatch(spinnerCreate(false));
    }
    catch (error) {
        dispatch(spinnerCreate(false));
        console.error(error);
    }
}

function getPatientsQueryParams(queryParams, isForChart, pagination, sort, isChangedSort) {
    let period = queryParams.filter.Period;
    let prescriptions = queryParams.filter.Prescriptions;
    let inchargeDate = queryParams.filter.InchargeDate;
    let dischargeDate = queryParams.filter.DischargeDate;
    let status = queryParams.filter.Status;
    let gender = queryParams.filter.Gender;
    let districts = queryParams.filter.District;
    let searchText = queryParams.filter.SearchText;
    let customerId = getCustomerId();
    let params = 'customerId:"' + customerId + '" ';

    params += 'startDate:"' + moment(period.split('-')[0].trim(), "DD/MM/YYYY").format("YYYY-MM-DD") + 'T00:00:00Z" endDate:"' + moment(period.split('-')[1].trim(), "DD/MM/YYYY").format("YYYY-MM-DD") + 'T23:59:59Z"';

    if (!isForChart) {
        if (isChangedSort) {
            params += 'first:50';
        } else {
            if (pagination) {
                params += 'first:' + pagination.Row + '';
                if (pagination.EndCursor) {
                    params += ' after:"' + pagination.EndCursor + '"';
                }
            } else {
                params += 'first:50';
            }
        }
    }

    if (status.length > 0 || gender.length > 0 || prescriptions.length > 0 || districts.length > 0 || searchText || inchargeDate || dischargeDate) {
        params += ' where:{';

        if (inchargeDate) {
            params += 'prescriptionDetailStartDate_gte:"' + moment(inchargeDate.split('-')[0].trim(), "DD/MM/YYYY").format("YYYY-MM-DD") + 'T00:00:00Z" prescriptionDetailStartDate_lte:"' + moment(inchargeDate.split('-')[1].trim(), "DD/MM/YYYY").format("YYYY-MM-DD") + 'T23:59:59Z"';
        }

        if (dischargeDate) {
            params += ' prescriptionDetailEndDate_gte:"' + moment(dischargeDate.split('-')[0].trim(), "DD/MM/YYYY").format("YYYY-MM-DD") + 'T00:00:00Z" prescriptionDetailEndDate_lte:"' + moment(dischargeDate.split('-')[1].trim(), "DD/MM/YYYY").format("YYYY-MM-DD") + 'T23:59:59Z"';
        }

        if (status.length > 0) {
            status.forEach((el, index) => {
                if (index === 0) {
                    params += ' patientState_in:[' + el + '';
                } else {
                    params += ',' + el + '';
                }
            });
            params += ']';
        }

        if (gender.length > 0) {
            gender.forEach((el, index) => {
                if (index === 0) {
                    params += ' gender_in:[' + el + '';
                } else {
                    params += ',' + el + '';
                }
            });
            params += ']';
        }

        if (prescriptions.length > 0) {
            prescriptions.forEach((el, index) => {
                if (index === 0) {
                    params += ' prescriptionDetailDescription_in:["' + el + '"';
                } else {
                    params += ',"' + el + '"';
                }
            });
            params += ']';
        }

        if (districts.length > 0) {
            districts.forEach((el, index) => {
                if (index === 0) {
                    if (el === "-") {
                        params += ' districtName_in:[' + null + '';
                    } else {
                        params += ' districtName_in:["' + el + '"';
                    }
                } else {
                    if (el === "-") {
                        params += ',' + null + '';
                    } else {
                        params += ',"' + el + '"';
                    }
                }
            });
            params += ']';
        }

        if (searchText) {
            params += ' OR:[{id_contains:"' + searchText.toUpperCase() + '"},{firstName_contains:"' + searchText.toUpperCase() + '"},{lastName_contains:"' + searchText.toUpperCase() + '"},{fullName_contains:"' + searchText.toUpperCase() + '"},{fullNameSearch_contains:"' + searchText.toUpperCase() + '"},{cF_contains:"' + searchText.toUpperCase() + '"}]';
        }

        params += '}';
    }

    if (sort) {
        let field = [];

        if (sort.dataField === "Id") {
            field.push("id");
        } else if (sort.dataField === "Nominative") {
            field.push("lastName");
            field.push("firstName");
        } else if (sort.dataField === "Gender") {
            field.push("gender");
        } else if (sort.dataField === "District") {
            field.push("districtName");
        } else if (sort.dataField === "Status") {
            field.push("patientState");
        } else if (sort.dataField === "Prescriptions") {
            field.push("prescriptionDetailDescription");
        } else if (sort.dataField === "Provision") {
            field.push("provisionsCount");
        } else if (sort.dataField === "InchargeDate") {
            field.push("prescriptionDetailStartDate");
        } else if (sort.dataField === "DischargeDate") {
            field.push("prescriptionDetailEndDate");
        } else if (sort.dataField === "TherapyDays") {
            field.push("therapyDays");
        }

        params += ' order_by: { ';

        field.forEach((el, index) => {
            if (index === 0) {
                params += el + ':' + sort.order.toUpperCase();
            } else {
                params += ',' + el + ':' + sort.order.toUpperCase();
            }
        });

        params += ' }';
    }

    return params;
}

export const fetchPatients = (queryParams, pagination, sort, isChangedSort) => async dispatch => {
    dispatch(spinnerCreate(true));

    let params = getPatientsQueryParams(queryParams, false, pagination, sort, isChangedSort);

    try {
        const response = await requestFromServer
            .findPatients(params);
        dispatch(actions.patientsFetched({ entities: response.data, holdPagination: pagination, isChangedSort: isChangedSort }));
        dispatch(spinnerCreate(false));
    }
    catch (error) {
        dispatch(spinnerCreate(false));
        console.error(error);
    }
};

export const fetchPatientsForFile = (queryParams, sort, callback) => async dispatch => {
    dispatch(spinnerCreate(true));

    let params = getPatientsQueryParams(queryParams, true, null, sort);

    try {
        const response = await requestFromServer
            .findPatients(params);
        dispatch(actions.patientsForFileFetched({ entities: response.data, callback }));
        dispatch(spinnerCreate(false));
    }
    catch (error) {
        dispatch(spinnerCreate(false));
        console.error(error);
    }
};

export const fetchPatientsChart = queryParams => async dispatch => {
    dispatch(spinnerCreate(true));

    let period = queryParams.filter.Period;

    let params = getPatientsQueryParams(queryParams, true);

    let dateFilterParams = 'startDate:"' + moment(period.split('-')[0].trim(), "DD/MM/YYYY").format("YYYY-MM-DD") + 'T00:00:00Z" endDate:"' + moment(period.split('-')[1].trim(), "DD/MM/YYYY").format("YYYY-MM-DD") + 'T23:59:59Z"';

    let patientsQuery = dateFilterParams + ' customerId:"' + getCustomerId() + '" patientReviewQuery:"query{patientsReview(' + params.replace(/"/g, '\\"') + '){nodes{id}}}"';

    try {
        const response = await requestFromServer.findPatientsChart(patientsQuery);
        dispatch(actions.patientsChartFetched({ entities: response.data }));
        dispatch(spinnerCreate(false));
    }
    catch (error) {
        dispatch(spinnerCreate(false));
        console.error(error);
    }
};

export const fetchPatient = id => async dispatch => {
    if (!id) {
        return dispatch(actions.patientFetched({ patientById: undefined }));
    }
    dispatch(spinnerCreate(true));
    try {
        const response = await requestFromServer.getPatientById(id);
        dispatch(actions.patientFetched({ patientById: response.data }));
        dispatch(spinnerCreate(false));
    }
    catch (error) {
        console.error(error);
        dispatch(spinnerCreate(false));
    }
};

export const fetchPatientChart = (id, queryParams) => async dispatch => {
    if (!id) {
        return dispatch(actions.patientFetched({ patientChart: undefined }));
    }
    dispatch(spinnerCreate(true));

    let params = 'patientId:"' + id + '" startDate:"' + queryParams + '"';

    try {
        const response = await requestFromServer.findPatientChart(params)
        dispatch(actions.patientChartFetched({ patientChart: response.data }));
        dispatch(spinnerCreate(false));
    } catch (error) {
        console.error(error);
        dispatch(spinnerCreate(false));
    }
};

export const fetchPatientInvoices = id => async dispatch => {
    if (!id) {
        return dispatch(actions.patientFetched({ patientInvoices: [] }));
    }
    dispatch(spinnerCreate(true));
    try {
        const response = await requestFromServer.getPatientInvoices(id);
        dispatch(actions.patientInvoicesFetched({ patientInvoices: response.data }));
        dispatch(spinnerCreate(false));
    } catch (error) {
        console.error(error);
        dispatch(spinnerCreate(false));
    }
}

export const fetchPatientProvisionsNear = id => async dispatch => {
    if (!id) {
        return dispatch(actions.patientFetched({ patientProvisionsNear: undefined }));
    }
    dispatch(spinnerCreate(true));
    try {
        const response = await requestFromServer.findPatientProvisionsNear(id);
        dispatch(actions.patientProvisionsNearFetched({ patientProvisionsNear: response.data }));
        dispatch(spinnerCreate(false));
    } catch (error) {
        console.error(error);
        dispatch(spinnerCreate(false));
    }
}

export const setInvoiceRow = invoice => dispatch => {
    dispatch(actions.setInvoiceRow({ data: invoice }));
}

export const openedPrescriptionAccordion = (openedAccordion) => dispatch => {
    setTimeout(() => {
        dispatch(actions.setOpenedPrescriptionAccordion({ data: openedAccordion }));
    }, 350);
}

export const setPrescriptionRow = prescription => dispatch => {
    dispatch(actions.setPrescriptionRow({ data: prescription }));
}
