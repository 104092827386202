export class ConsumableModel {
    constructor() {
        this.Photo = "";
        this.ConsumableType = "";
        this.BrandAndModel = "";
        this.Description = "";
        this.Serial = "";
        this.LotNumber = "";
        this.Quantity = 0;
        this.DeliveryDate = "";
        this.PickUpDate = "";
        this.Assets = [];
    }
}