import React from "react";
import {
    sortCaret,
    headerSortingClasses,
} from "../../../../_metronic/_helpers";
import BootstrapTable from "react-bootstrap-table-next";
import { defaultProvisionDevicesSorted } from "./ProvisionInfoUIHelpers";
import { tableSortByDate } from "../../../component/table/tableSort";
import "../../../assets/css/table.css";
import { AssetsColumnFormatter, /*FotoColumnFormatter,*/ DevicesFotoColumnFormatter } from "../../../component/table/column-formatters/ActionsColumnFormatter";

export function ProvisionDevicesTable({ provisionDevices, history, provisionType }) {

    const columns = [
        {
            isDummyField: true,
            dataField: "Photo",
            text: "",
            align: "center",
            headerAlign: 'center',
            //formatter: FotoColumnFormatter,
            formatter: DevicesFotoColumnFormatter, // soluzione momentanea in quanto a volte arrivano foto 'rotte' dal db appena sistemato rimettere quello sopra
        },
        {
            dataField: "DeviceType",
            text: "Tipologia Dispositivo",
            sort: true,
            align: "center",
            headerAlign: 'center',
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        {
            dataField: "BrandAndModel",
            text: "Descrizione",
            sort: true,
            align: "center",
            headerAlign: 'center',
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        {
            dataField: "Serial",
            text: "Seriale",
            sort: false,
            align: "center",
            headerAlign: 'center',
        },
        {
            dataField: "ItemNumber",
            text: "Codice Articolo",
            sort: false,
            align: "center",
            headerAlign: 'center',
        }

    ];

    //controllo se è di tipo assistenza
    if (provisionType >= 3 && provisionType <= 6) {
        columns.push(
            {
                dataField: "DeliveryDate",
                text: "Data",
                sort: true,
                align: "center",
                headerAlign: 'center',
                sortFunc: tableSortByDate,
                sortCaret: sortCaret,
                headerSortingClasses,
            },
            {
                isDummyField: true,
                dataField: "",
                text: "Documentazione",
                align: "center",
                headerAlign: 'center',
                formatter: AssetsColumnFormatter,
            }
        );
    } else if (provisionType === 11) {
        columns.push(
            {
                dataField: "DeliveryDate",
                text: "Data Consegna",
                sort: true,
                align: "center",
                headerAlign: 'center',
                sortFunc: tableSortByDate,
                sortCaret: sortCaret,
                headerSortingClasses,
            },
            {
                isDummyField: true,
                dataField: "",
                text: "Documentazione",
                align: "center",
                headerAlign: 'center',
                formatter: AssetsColumnFormatter,
            }
        );
    } else if (provisionType === 13) {
        columns.push(
            {
                dataField: "PickUpDate",
                text: "Data Ritiro",
                sort: true,
                align: "center",
                headerAlign: 'center',
                sortFunc: tableSortByDate,
                sortCaret: sortCaret,
                headerSortingClasses,
            },
            {
                isDummyField: true,
                dataField: "",
                text: "Documentazione",
                align: "center",
                headerAlign: 'center',
                formatter: AssetsColumnFormatter,
            }
        );
    }
    else {
        columns.push(
            {
                dataField: "DeliveryDate",
                text: "Data Consegna",
                sort: true,
                align: "center",
                headerAlign: 'center',
                sortFunc: tableSortByDate,
                sortCaret: sortCaret,
                headerSortingClasses,
            },
            {
                dataField: "PickUpDate",
                text: "Data Ritiro",
                sort: true,
                align: "center",
                headerAlign: 'center',
                sortFunc: tableSortByDate,
                sortCaret: sortCaret,
                headerSortingClasses,
            },
            {
                isDummyField: true,
                dataField: "",
                text: "Documentazione",
                align: "center",
                headerAlign: 'center',
                formatter: AssetsColumnFormatter,
            }
        );
    }

    const selectRow = {
        mode: 'checkbox',
        clickToSelect: true,
        hideSelectAll: true,
        hideSelectColumn: true,
        onSelect: (row, isSelect, rowIndex, e) => {
            if (e.target.localName === "td") {
                history.push(`/dispositivi/${row.Id}/dettaglio`);
            }
        }
    }

    return (
        <BootstrapTable
            wrapperClasses="table-responsive"
            bordered={false}
            classes="table-head-custom table-hover table-vertical-center"
            bootstrap4
            keyField="Id"
            data={provisionDevices}
            columns={columns}
            defaultSorted={defaultProvisionDevicesSorted}
            noDataIndication={"Non sono presenti dati"}
            selectRow={selectRow}
        >
        </BootstrapTable>
    );
}