import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import * as actions from "../../../actions/provisions/provisionsActions";
import { useProvisionsUIContext } from "../ProvisionsUIContext"
import BootstrapTable from "react-bootstrap-table-next";

export function ProvisionsInProgressTable() {

    const provisionsUIContext = useProvisionsUIContext();
    const provisionsUIProps = useMemo(() => {
        return {
            queryParams: provisionsUIContext.queryParams,
        };
    }, [provisionsUIContext]);

    const { currentState } = useSelector(
        (state) => ({ currentState: state.provisions }),
        shallowEqual
    );

    const { provisionsInProgressList, provisionsInProgressColumns } = currentState;

    const dispatch = useDispatch();
    useEffect(() => {

        dispatch(actions.fetchProvisionsInProgress(provisionsUIProps.queryParams));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [provisionsUIProps.queryParams, dispatch]);

    return (
        <>
            {
                provisionsInProgressColumns && provisionsInProgressColumns.length > 0
                    ? <BootstrapTable
                        wrapperClasses="table-responsive"
                        bordered={false}
                        classes="table-head-custom table-vertical-center"
                        bootstrap4
                        keyField="Id"
                        data={provisionsInProgressList}
                        columns={provisionsInProgressColumns}
                        noDataIndication={"Non sono presenti dati"}
                    >
                    </BootstrapTable>
                    : null
            }
        </>

    );
}