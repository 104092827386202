/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { checkIsActive } from "../../../../_helpers";

export function HeaderMenu({ layoutProps }) {
    const location = useLocation();
    const getMenuItemActive = (url) => {
        return checkIsActive(location, url) ? "menu-item-active" : "";
    }

    return <div
        id="kt_header_menu"
        className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}
        {...layoutProps.headerMenuAttributes}
    >
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
            {/* <li
                data-menu-toggle={layoutProps.menuDesktopToggle}
                aria-haspopup="true"
                className={`menu-item menu-item-rel ${getMenuItemActive('/indicatori-di-servizio')}`}>
                <a className="menu-link" href="/indicatori-di-servizio">
                    <span className="menu-text">Indicatori Di Servizio</span>
                </a>
            </li> */}
            <li
                className={`menu-item menu-item-rel ${getMenuItemActive('/pazienti')}`}
                aria-haspopup="true">
                <a className="menu-link" href="/pazienti">
                    <span className="menu-text">Pazienti</span>
                </a>
            </li>
            <li
                data-menu-toggle={layoutProps.menuDesktopToggle}
                aria-haspopup="true"
                className={`menu-item menu-item-rel ${getMenuItemActive('/prestazioni')}`}>
                <a className="menu-link" href="/prestazioni">
                    <span className="menu-text">Prestazioni</span>
                </a>
            </li>
            <li
                data-menu-toggle={layoutProps.menuDesktopToggle}
                aria-haspopup="true"
                className={`menu-item menu-item-rel ${getMenuItemActive('/dispositivi')}`}>
                <a className="menu-link" href="/dispositivi">
                    <span className="menu-text">Dispositivi</span>
                </a>
            </li>
            {/* <li
                data-menu-toggle={layoutProps.menuDesktopToggle}
                aria-haspopup="true"
                className={`menu-item menu-item-rel ${getMenuItemActive('/personale')}`}>
                <a className="menu-link" href="/personale">
                    <span className="menu-text">Personale</span>
                </a>
            </li> */}
            {/* <li
                data-menu-toggle={layoutProps.menuDesktopToggle}
                aria-haspopup="true"
                className={'menu-item menu-item-submenu menu-item-rel ' + (getMenuItemActive('/costi') || getMenuItemActive('/fatture'))}>
                <NavLink className="menu-link menu-toggle" to="/fatturazione">
                    <span className="menu-text">Fatturazione</span>
                    <i className="menu-arrow"></i>
                </NavLink>
                <div className="menu-submenu menu-submenu-classic menu-submenu-left">
                    <ul className="menu-subnav">
                        <li className={`menu-item ${getMenuItemActive('/costi')}`}>
                            <a className="menu-link" href="/costi">
                                <span className="menu-text">Costi</span>
                            </a>
                        </li>
                        <li className={`menu-item ${getMenuItemActive('/fatture')}`}>
                            <a className="menu-link" href="/fatture">
                                <span className="menu-text">Fatture</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </li> */}
        </ul>
    </div>;
}
