import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import { devicesSlice } from "../app/actions/devices/devicesSlice";
import { patientsSlice } from "../app/actions/patients/patientsSlice";
import { provisionsSlice } from "../app/actions/provisions/provisionsSlice";
import { spinnerSlice } from "../app/actions/spinner/spinnerSlice";
import { staffsSlice } from "../app/actions/staffs/staffsSlice";
import { invoicesSlice } from "../app/actions/invoices/invoicesSlice";
import { serviceIndicatorsSlice } from "../app/actions/serviceIndicators/serviceIndicatorsSlice";
import { usersSlice } from "../app/actions/users/usersSlice";
import { reducer as oidcReducer } from 'redux-oidc';


export const rootReducer = combineReducers({
  auth: auth.reducer,
  //auth: authSlice.reducer,
  devices: devicesSlice.reducer,
  invoices: invoicesSlice.reducer,
  //modals: modalsSlice.reducer,
  patients: patientsSlice.reducer,
  provisions: provisionsSlice.reducer,
  spinner: spinnerSlice.reducer,
  staffs: staffsSlice.reducer,
  serviceIndicators: serviceIndicatorsSlice.reducer,
  users: usersSlice.reducer,
  oidc: oidcReducer
});
