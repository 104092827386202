import React, { useEffect } from "react";
import { useSubheader } from "../../../../_metronic/layout";
import {
    Card,
    CardBody,
    CardHeader,
} from "../../../../_metronic/_partials/controls";
import { PatientsTable } from "./PatientsTable";
import { PatientsChart } from "./PatientsChart";
import { PatientsFilters } from "./PatientsFilters";
import { KTScrollFilters } from "../../../component/scroll/Scroll";

export const PatientsHome = () => {
    const suhbeader = useSubheader();
    suhbeader.setTitle("Pazienti");

    useEffect(() => {
        KTScrollFilters.init("patientsFilter")
    }, []);

    return (
        <div className="row" style={{ zIndex: "1" }}>
            <div className="col-lg-2" style={{ zIndex: "1" }}>
                <Card className="card-sticky" id="patientsFilter">
                    <CardHeader title="Filtri" className="font-weight-bolder">
                    </CardHeader>
                    <CardBody className="offcanvas-content">
                        <PatientsFilters />
                    </CardBody>
                </Card>
            </div>
            <div className="col-lg-10 card-lg-10" style={{ zIndex: "1" }}>
                <Card>
                    <CardHeader title="Grafico Pazienti/Trattamenti" className="font-weight-bolder"/>
                    <CardBody>
                        <PatientsChart />
                    </CardBody>
                </Card>
                <PatientsTable />
            </div>
        </div>
    );
};
