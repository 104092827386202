
//import moment from "moment";

export const documentTypeTitles = ["Sconosciuto", "Fattura", "Nota di Credito"];
export const defaultInvoiceListTableSorted = [{ dataField: "InvoiceDate", order: "desc" }];

// export const initialInvoiceFilter = {
//     filter: {
//         SearchInvoiceText: "",
//         InvoiceDate: moment().subtract(1, "month").format('DD/MM/YYYY') + " - " + moment().format('DD/MM/YYYY'),
//         DocumentType: undefined,
//         InvoicesDistricts: undefined,
//         InvoiceAmount: undefined
//     },
// };
