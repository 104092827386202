import React from "react";
import {
    sortCaret,
    headerSortingClasses,
} from "../../../../_metronic/_helpers";
import BootstrapTable from "react-bootstrap-table-next";
import { defaultPatientDevicesSorted } from "./PatientInfoUIHelpers"
import { AssetsColumnFormatter, /*FotoColumnFormatter,*/ DevicesFotoColumnFormatter } from "../../../component/table/column-formatters/ActionsColumnFormatter";
import { tableSortByDate } from "../../../component/table/tableSort";
import "../../../assets/css/table.css";

export function PatientDevicesTable({ devices, history }) {

    const columns = [
        {
            isDummyField: true,
            dataField: "Photo",
            text: "",
            align: "center",
            headerAlign: 'center',
            //formatter: FotoColumnFormatter,
            formatter: DevicesFotoColumnFormatter, // soluzione momentanea in quanto a volte arrivano foto 'rotte' dal db appena sistemato rimettere quello sopra
        },
        {
            dataField: "DeviceType",
            text: "Tipologia Dispositivo",
            sort: true,
            align: "center",
            headerAlign: 'center',
            sortCaret: sortCaret,
            headerSortingClasses
        },
        {
            dataField: "BrandAndModel",
            text: "Descrizione",
            sort: true,
            align: "center",
            headerAlign: 'center',
            sortCaret: sortCaret,
            headerSortingClasses
        },
        {
            dataField: "Serial",
            text: "Seriale",
            sort: true,
            align: "center",
            headerAlign: 'center',
            sortCaret: sortCaret,
            headerSortingClasses
        },
        {
            dataField: "ItemNumber",
            text: "Codice Articolo",
            sort: true,
            align: "center",
            headerAlign: 'center',
            sortCaret: sortCaret,
            headerSortingClasses
        },
        {
            dataField: "DeliveryDate",
            text: "Data Consegna",
            sort: true,
            align: "center",
            headerAlign: 'center',
            sortFunc: tableSortByDate,
            sortCaret: sortCaret,
            headerSortingClasses
        },
        {
            dataField: "PickUpDate",
            text: "Data Ritiro",
            sort: true,
            align: "center",
            headerAlign: 'center',
            sortFunc: tableSortByDate,
            sortCaret: sortCaret,
            headerSortingClasses
        },
        {
            isDummyField: true,
            dataField: "Assets",
            text: "Documentazione",
            align: "center",
            headerAlign: 'center',
            formatter: AssetsColumnFormatter
        },
    ];

    const selectRow = {
        mode: 'checkbox',
        clickToSelect: true,
        hideSelectAll: true,
        hideSelectColumn: true,
        onSelect: (row, isSelect, rowIndex, e) => {
            if (e.target.localName === "td") {
                history.push(`/dispositivi/${row.Id}/dettaglio`)
            }
        }
    }

    return (
        <BootstrapTable
            wrapperClasses="table-responsive"
            bordered={false}
            classes="table-head-custom table-hover table-vertical-center"
            bootstrap4
            keyField="Id"
            data={devices === null ? [] : devices}
            columns={columns}
            defaultSorted={defaultPatientDevicesSorted}
            noDataIndication={"Non sono presenti dati"}
            selectRow={selectRow}
        >
        </BootstrapTable>
    );
}