import { createSlice } from "@reduxjs/toolkit";
import { CostModel } from "../../models/costModel";
import { InvoiceTableModel } from "../../models/invoiceTableModel";
import moment from "moment";
import { sortCaret, headerSortingClasses } from "../../../_metronic/_helpers";
import { documentTypeTitles } from "../../pages/invoices/InvoicesUIHelpers";
import { PaginationModel } from "../../models/paginationModel";

const initialInvoicesState = {
    costsList: [],
    invoicesPagination: new PaginationModel(),
    costsPagination: new PaginationModel(),
    invoicesList: [],
    costsNotAccounted: [],
    costsNotAccountedColumns: [],
    costsChartMonth: [],
    costChartData: [],
    filterPrescriptors: [],
    filterPrescriptions: [],
    filterDocumentType: [],
    filterCostsDistricts: [],
    filterCostsMaxAmount: 0,
    filterCostsMinAmount: 0,
    filterInvoicesDistricts: [],
    filterInvoicesMaxAmount: 0,
    filterInvoicesMinAmount: 0,
    selectedCost: []
};

export const invoicesSlice = createSlice({
    name: "invoices",
    initialState: initialInvoicesState,
    reducers: {
        costsFetched: (state, action) => {
            try {
                const { entities, holdPagination, isChangedSort } = action.payload;
                if (entities.data.billingTable) {
                    let newCostsList = [];
                    entities.data.billingTable.nodes.forEach((el) => {
                        let model = new CostModel();
                        let cost = el;
                        model.CostId = cost.id;
                        model.ClientId = cost.clientID ? cost.clientID : "-";
                        model.District = cost.districtName ? cost.districtName : "-";
                        model.Prescription = cost.prescriptionName;
                        model.Prescriptor = cost.prescriptorFullName;
                        model.TherapyDays = cost.therapyDays;
                        let monthNumber = "";
                        if (el.monthNumber < 10) {
                            monthNumber = "0" + cost.monthNumber;
                        } else {
                            monthNumber = cost.monthNumber;
                        }
                        model.MonthNumber = cost.year + "" + monthNumber;
                        model.Amount = cost.amount + " €";

                        newCostsList.push(model);
                    });

                    let pageInfo = entities.data.billingTable.pageInfo;
                    let newPagination = new PaginationModel();
                    newPagination.EndCursor = pageInfo.endCursor;
                    newPagination.HasNextPage = pageInfo.hasNextPage;

                    if (isChangedSort) {
                        state.costsList = newCostsList;
                    } else {
                        state.costsList = state.costsList.length > 0 && holdPagination ? state.costsList.concat(newCostsList) : newCostsList;
                    }
                    state.costsPagination = newPagination;
                } else {
                    state.costsList = [];
                    state.costsPagination = new PaginationModel();
                }
            } catch (error) {
                console.error(error);
            }
        },
        costsForFileFetched: (state, action) => {
            try {
                const { entities, callback } = action.payload;
                let newCostsList = [];
                if (entities.data.billingTable) {
                    entities.data.billingTable.nodes.forEach((el) => {
                        let model = new CostModel();
                        let cost = el;
                        model.CostId = cost.id;
                        model.ClientId = cost.clientID ? cost.clientID : "-";
                        model.District = cost.districtName ? cost.districtName : "-";
                        model.Prescription = cost.prescriptionName;
                        model.Prescriptor = cost.prescriptorFullName;
                        model.TherapyDays = cost.therapyDays;
                        let monthNumber = "";
                        if (el.monthNumber < 10) {
                            monthNumber = "0" + cost.monthNumber;
                        } else {
                            monthNumber = cost.monthNumber;
                        }
                        model.MonthNumber = cost.year + "" + monthNumber;
                        model.Amount = cost.amount + " €";

                        newCostsList.push(model);
                    });
                }
                callback(newCostsList);
            } catch (error) {
                console.error(error);
            }
        },
        costsChartFetched: (state, action) => {
            try {
                const { entities } = action.payload;
                if (entities.data.billingHistoryGraph) {

                    let invoiceChartArray1 = [];

                    entities.data.billingHistoryGraph.nodes[0].districtMonthlyAmounts.forEach((el) => {
                        let invoiceChartObject = {};
                        let monthNumber = "";
                        if (el.monthNumber < 10) {
                            monthNumber = "0" + el.monthNumber;
                        } else {
                            monthNumber = el.monthNumber;
                        }
                        invoiceChartObject.YearMonthNumber = el.year + "" + monthNumber;
                        invoiceChartObject.MonthYearName = el.monthName + " " + el.year;
                        invoiceChartObject.DistrictName = el.districtName;
                        invoiceChartObject.Amount = el.amount;
                        invoiceChartArray1.push(invoiceChartObject);
                    });
                    invoiceChartArray1.sort(function (a, b) {
                        var x = a.YearMonthNumber, y = b.YearMonthNumber;
                        return ((x < y) ? -1 : ((x > y) ? 1 : 0));
                    });

                    let chartMonth = [];
                    let chartDistrictName = [];

                    invoiceChartArray1.forEach((el) => {
                        if (!chartMonth.includes(el.MonthYearName)) {
                            chartMonth.push(el.MonthYearName);
                        }
                        if (!chartDistrictName.includes(el.DistrictName)) {
                            chartDistrictName.push(el.DistrictName);
                        }
                    });

                    let dataset = [];

                    chartDistrictName.forEach((element) => {
                        let district = {};
                        district.seriesname = element;
                        district.data = [];
                        chartMonth.forEach((e) => {
                            let amount = invoiceChartArray1.filter(
                                (el) => {
                                    return e === el.MonthYearName && element === el.DistrictName
                                });
                            district.data.push({ value: amount.length > 0 ? amount[0].Amount : null });
                        });
                        dataset.push(district);
                    });

                    state.costsChartMonth = chartMonth;
                    state.costChartData = dataset;
                } else {
                    state.costsChartMonth = [];
                    state.costChartData = [];
                }
            } catch (error) {
                console.error(error);
            }
        },
        costsNotAccountedFetched: (state, action) => {
            try {
                const { entities } = action.payload;
                if (entities.data.billingMonthMatrix) {

                    let newCostsNotAccountedList = [];
                    let columns = [
                        {
                            dataField: 'districtname',
                            text: 'Distretto',
                            sort: true,
                            headerAlign: 'center',
                            align: "center",
                            sortCaret: sortCaret,
                            headerSortingClasses,
                        }
                    ];

                    action.payload.entities.data.billingMonthMatrix.nodes.forEach((el) => {

                        let prescription = el.prescriptionName;

                        let row = newCostsNotAccountedList.find((element) => { return element.districtname === el.districtname });

                        if (!row) {
                            row = {
                                districtname: el.districtname
                            };
                            newCostsNotAccountedList.push(row);
                        }
                        row[el.prescriptionName] = el.amount + " €";

                        if (columns.filter(element => { return element.dataField === el.prescriptionName }).length === 0) {
                            columns.push(
                                {
                                    dataField: el.prescriptionName,
                                    text: prescription,
                                    headerAlign: 'center',
                                    align: "center",
                                    sort: true,
                                    sortCaret: sortCaret,
                                    headerSortingClasses,
                                }
                            );
                        }

                    });

                    state.costsNotAccounted = newCostsNotAccountedList;
                    state.costsNotAccountedColumns = columns;
                } else {
                    state.costsNotAccounted = [];
                    state.costsNotAccountedColumns = [];
                }
            } catch (error) {
                console.error(error);
            }
        },
        invoicesFetched: (state, action) => {
            try {
                const { entities, holdPagination, isChangedSort } = action.payload;
                if (entities.data.billingReview) {
                    let newInvoicesList = [];
                    entities.data.billingReview.nodes.forEach((el, index) => {
                        let model = new InvoiceTableModel();
                        model.Id = el.id;
                        if (el.documentType === "UNKNOWN") {
                            model.DocumentType = 0;
                        } else if (el.documentType === "INVOICE") {
                            model.DocumentType = 1;
                        } else if (el.documentType === "CREDITNOTE") {
                            model.DocumentType = 2;
                        }
                        model.CustomerId = el.customerId;
                        model.DocumentCode = el.documentCode;
                        model.District = el.districtName;
                        model.InvoiceDate = moment(el.invoiceDate).format("DD/MM/YYYY");
                        model.Amount = el.amount + " €";
                        model.DocumentLink = el.documentLink;

                        newInvoicesList.push(model);
                    });

                    let pageInfo = entities.data.billingReview.pageInfo;
                    let newPagination = new PaginationModel();
                    newPagination.EndCursor = pageInfo.endCursor;
                    newPagination.HasNextPage = pageInfo.hasNextPage;

                    if (isChangedSort) {
                        state.invoicesList = newInvoicesList;
                    } else {
                        state.invoicesList = state.invoicesList.length > 0 && holdPagination ? state.invoicesList.concat(newInvoicesList) : newInvoicesList;
                    }
                    state.invoicesPagination = newPagination;
                } else {
                    state.invoicesList = [];
                    state.invoicesPagination = new PaginationModel();
                }
            } catch (error) {
                console.error(error);
            }
        },
        invoicesForFileFetched: (state, action) => {
            try {
                const { entities, callback } = action.payload;
                let newInvoicesList = [];
                if (entities.data.billingReview) {
                    entities.data.billingReview.nodes.forEach((el, index) => {
                        let model = new InvoiceTableModel();
                        model.Id = el.id;
                        if (el.documentType === "UNKNOWN") {
                            model.DocumentType = 0;
                        } else if (el.documentType === "INVOICE") {
                            model.DocumentType = 1;
                        } else if (el.documentType === "CREDITNOTE") {
                            model.DocumentType = 2;
                        }
                        model.CustomerId = el.customerId;
                        model.DocumentCode = el.documentCode;
                        model.District = el.districtName;
                        model.InvoiceDate = moment(el.invoiceDate).format("DD/MM/YYYY");
                        model.Amount = el.amount + " €";
                        model.DocumentLink = el.documentLink;

                        newInvoicesList.push(model);
                    });
                }
                callback(newInvoicesList);
            } catch (error) {
                console.error(error);
            }
        },
        setCostRow: (state, action) => {
            try {
                state.selectedCost = action.payload.data;
            } catch (error) {
                console.error(error);
            }
        },
        invoicesFitlersFetched: (state, action) => {
            try {
                const { entities } = action.payload;
                if (entities.data.billingReviewFilters) {
                    let districts = entities.data.billingReviewFilters.districts;
                    let documentTypes = entities.data.billingReviewFilters.documentTypes;
                    let maxAmount = entities.data.billingReviewFilters.maxAmount;
                    let minAmount = entities.data.billingReviewFilters.minAmount;

                    let districtsArray = [];
                    districts.forEach((e) => {
                        if (e) {
                            districtsArray.push(e);
                        } else {
                            districtsArray.push("-");
                        }
                    });

                    state.filterInvoicesDistricts = districtsArray.sort();

                    let documentTypesArray = [];
                    documentTypes.forEach((el) => {
                        if (el === "UNKNOWN") {
                            documentTypesArray.push({ label: documentTypeTitles[0], value: el });
                        } else if (el === "INVOICE") {
                            documentTypesArray.push({ label: documentTypeTitles[1], value: el });
                        } else {
                            documentTypesArray.push({ label: documentTypeTitles[2], value: el });
                        }
                    });

                    state.filterDocumentType = documentTypesArray;
                    state.filterInvoicesMaxAmount = maxAmount;
                    state.filterInvoicesMinAmount = minAmount;
                } else {
                    state.filterDocumentType = [];
                    state.filterInvoicesMaxAmount = 0;
                    state.filterInvoicesMinAmount = 0;
                }
            } catch (error) {
                console.error(error);
            }
        },
        costsFitlersFetched: (state, action) => {
            try {
                const { entities } = action.payload;
                if (entities.data.billingTableFilters) {
                    let districts = entities.data.billingTableFilters.districts;
                    let prescriptions = entities.data.billingTableFilters.prescriptions;
                    let prescriptors = entities.data.billingTableFilters.prescriptors;
                    let maxCost = entities.data.billingTableFilters.maxCost;
                    let minCost = entities.data.billingTableFilters.minCost;

                    let districtsArray = [];
                    districts.forEach((e) => {
                        if (e) {
                            districtsArray.push(e);
                        } else {
                            districtsArray.push("-");
                        }
                    });

                    state.filterCostsDistricts = districtsArray.sort();
                    state.filterCostsMaxAmount = maxCost;
                    state.filterCostsMinAmount = minCost;
                    state.filterPrescriptions = prescriptions.sort();
                    state.filterPrescriptors = prescriptors.sort().map((el) => {
                        return { value: el, label: el }
                    });
                } else {
                    state.filterCostsDistricts = [];
                    state.filterCostsMaxAmount = 0;
                    state.filterCostsMinAmount = 0;
                    state.filterPrescriptions = [];
                    state.filterPrescriptors = [];
                }
            } catch (error) {
                console.error(error);
            }
        }
    }
});